import React, { FC, ReactNode } from "react";
import { isTruthy } from "../../../helpers/Helper";

interface ButtonProps {
    colorCode?: string;
    colorClass?: string;
    children: ReactNode;
    onClick: () => void;
    isAvailable?: boolean;
    customStyle?: React.CSSProperties;
}

export const CustomButtons: FC<ButtonProps> = ({ colorCode, colorClass = 'btn-primary', children, onClick, isAvailable, customStyle = {} }) => {
    let buttonStyle = {};
    if (colorCode) {
        colorClass = '';
        buttonStyle = {
            backgroundColor: colorCode,
            borderColor: colorCode,
        };
    }

    return (
        <button
            type="button"
            className={`btn m-1 ${colorClass} ${isTruthy(isAvailable) ? 'disabled' : ''}`}
            style={{...buttonStyle, ...customStyle}}
            onClick={isAvailable ? undefined : onClick}
            aria-disabled={isTruthy(isAvailable)}
        >
            {children}
        </button>
    );
}
