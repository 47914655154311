import { makeAutoObservable } from 'mobx';
import { getObject } from '../helpers/Helper';
import { makePersistable } from 'mobx-persist-store';

interface IValues {
    [key: string]: any;
}

class StopLoss {
    positions: any[] = [];
    settings: any[] = [];
    isOrderApirunning: boolean = false

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'StopLossStore',
            properties: [],
            storage: localStorage
        });
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        try {
            const obj = getObject(values);
            Object.keys(obj).forEach((key) => {
                if (key in this) {
                    (this as Record<string, any>)[key] = obj[key];
                } else {
                    console.warn(`Key "${key}" does not exist on the target object.`);
                }
            });
        } catch (error) {
            console.error('An error occurred while updating values:', error);
        }
    };

    reset = () => {
        this.positions = [];
        this.settings = [];
        this.isOrderApirunning = false;
    }
}
const stopLoss = new StopLoss();
export default stopLoss;
