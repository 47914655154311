import { makeAutoObservable } from 'mobx';
import { getObject } from '../helpers/Helper';
import { makePersistable } from 'mobx-persist-store';

interface IValues {
    [key: string]: any;
}

class Config {
    apiError: string = '';
    apiErrorCode: number = 0;
    apiSuccess: string = '';
    authorization: string = '';
    session_id: string = '';
    request_id: string = '';
    api_encryption_key: string = '';
    device_encryption_key: string = '';
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'ConfigStore',
            properties: ['authorization','session_id','request_id','api_encryption_key','device_encryption_key'],
            storage: localStorage
        });
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        Object.keys(getObject(values)).forEach((key) => {
            (this as any)[key] = values[key];
        });
    }

    reset = () => {
        this.apiError = '';
        this.apiErrorCode = 0;
        this.apiSuccess = '';
        this.authorization = '';
        this.session_id = '';
        this.request_id = '';
        this.api_encryption_key = '';
        this.device_encryption_key = '';
        this.loading = false;
    }
}
const config = new Config();
export default config;
