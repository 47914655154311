import React, { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Spinner } from '../Spinners/Spinner';

interface CustomDialogProps {
    visible: boolean;
    title: string;
    children?: ReactNode;
    handleClose: () => void;
    onSubmitCallback: () => void;
    onCancelCallback: () => void;
    loading?: boolean;
} 

export const CustomDialog:React.FC<CustomDialogProps> = ({
    visible,
    title,
    children,
    handleClose,
    onSubmitCallback,
    onCancelCallback,
    loading = false
}) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            open={visible}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                {loading ?
                    <div className='mr-5'>
                        <Spinner />
                    </div>
                    :
                    <>
                        <Button onClick={onCancelCallback}>Cancel</Button>
                        <Button onClick={onSubmitCallback} autoFocus>Submit</Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    );
}
