import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Spinner } from '../Spinners/Spinner';

interface AlertDialogProps {
    visible: boolean;
    message: string;
    handleClose: () => void;
    onSubmitCallback: () => void;
    onCancelCallback: () => void;
    loading?: boolean;
} 

export const AlertDialog:React.FC<AlertDialogProps> = ({
    visible,
    message,
    handleClose,
    onSubmitCallback,
    onCancelCallback,
    loading = false
}) => {
    return (
        <Dialog
            open={visible}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirmation Required"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {loading ?
                    <div className='ml-5'>
                        <Spinner />
                    </div>
                    :
                    <>
                        <Button onClick={onCancelCallback}>Cancel</Button>
                        <Button onClick={onSubmitCallback} autoFocus>Submit</Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    );
}
