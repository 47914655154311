import { useLocation, useNavigate } from 'react-router-dom';

export function useCustomLocation() {
    const location = useLocation();

    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);

    // Convert queryParams to a more accessible object
    const queryParamsObject: any = {};
    queryParams.forEach((value, key) => {
        queryParamsObject[key] = value;
    });

    // Return the location object with an additional property for the parsed query params
    return {
        ...location,
        query: queryParamsObject,
    };
}

export function useCustomNavigation() {
    const navigate = useNavigate()
    return navigate;
}

export const formatCurrency = (number: number | string): string => {
    // Convert the number to a string
    if (typeof number === 'string' && isNaN(parseFloat(number))) {
        return number;
    }
    
    const num = parseFloat(number as string);
    const isNegative = num < 0;
    const numStr = Math.abs(num).toFixed(2);
    
    // Split the string into integer and decimal parts
    const [integerPart, decimalPart] = numStr.split('.');
    
    // Reverse the integer part string to process from least significant digit
    const reversedInteger = integerPart.split('').reverse().join('');
    
    // Process the string to insert commas
    const parts: string[] = [];
    parts.push(reversedInteger.slice(0, 3)); // first three digits
    for (let i = 3; i < reversedInteger.length; i += 2) {
        parts.push(reversedInteger.slice(i, i + 2)); // next two digits at a time
    }
    
    // Join the parts and reverse back
    let formattedInteger = parts.join(',').split('').reverse().join('');
    
    // Add the negative sign back if necessary
    if (isNegative) {
        formattedInteger = '-' + formattedInteger;
    }
    
    // Combine with the decimal part
    const formattedNumber = `${formattedInteger}.${decimalPart}`;
    
    return formattedNumber;
}