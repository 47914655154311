import React from 'react';
import Typography from '@mui/material/Typography';

interface CustomTypographyProps {
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline';
    color?: string;
    fontWeight?: string;
    styles?: React.CSSProperties;
    text: string | number;
}

export const CustomTypography: React.FC<CustomTypographyProps> = ({ variant = 'body2', color, fontWeight, styles, text }) => {
    return (
        <Typography variant={variant} style={{ color,fontWeight, ...styles }}>
            {text}
        </Typography>
    );
};
