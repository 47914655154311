import Cookies from 'universal-cookie';
import { LocalStoreEncryption, LocalStoreDecryption } from './Crypto';
import { baseUrl } from './Config';

const cookies = new Cookies();

// Create Local storage with Encryption
export const SetLocalStorage = (key: string, value: string | boolean, expiresTime = 15552000, encrypt = true) => {
	try {
		let encryptValue: string | boolean | null = value;
		if (encrypt && typeof value === 'string') {
			encryptValue = LocalStoreEncryption(value);
		}
		cookies.set(key, encryptValue, {path: baseUrl, maxAge: expiresTime});
		return true;
	}catch (error) {
		return false;
	}
}

// Get Local storage value with Encryption
export const GetLocalStorage = (key: string, decrypt = true) => {
	try {
		const data = cookies.get(key);
	    if (data) {
			let decryptValue = data;
	    	if (decrypt) {
				decryptValue = LocalStoreDecryption(data);
			}
			return decryptValue;
	    }
	    return false;
	} catch (error) {
		return false;
	}
}

// Remove Local Storage
export const RemoveLocalStorage = (key: string) => {
	try {
		cookies.remove(key);
		return true;
	} catch (error) {
		return true;
	}
}