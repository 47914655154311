// helpers/api.ts
import { callApi } from "../helpers/Api";
import { baseUrl } from "../helpers/Config";
import openInterest from "../subscriber/OpenInterest";
import option_chain from "../subscriber/OptionChain";
import broker from "../subscriber/Broker";
import users from "../subscriber/Users";
import orders from "../subscriber/Orders";
import stopLoss from "../subscriber/StopLoss";
import Virtual_order from "../subscriber/VirtualOrder";
import { Brokers, ODepth, OExchange, OSymbol } from "../utils/constant";
import { getStrategies, getStrategyTrade } from "./Strategies";
import { isObjectTruthy } from "../helpers/Helper";
import { getStopLoss } from "./StopLoss";
import targetProfits from "../subscriber/TargetProfits";

// // Utility function to clear cookies
// const clearCookies = () => {
//     const cookies = document.cookie.split("; ");
//     for (let cookie of cookies) {
//         const eqPos = cookie.indexOf("=");
//         const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//         document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
//     }
// };

// // Utility function to clear session details
// const clearSessionDetails = () => {
//     clearCookies(); // Clear all cookies
//     // localStorage.clear(); // Clear local storage
//     // sessionStorage.clear(); // Clear session storage
// };

// after login initialization.
const initialization = () => {
    // reseting all the MOBX variable
    orders.reset();
    openInterest.reset();
    stopLoss.reset();
    Virtual_order.reset();
    
    // setting the default the market data
    option_chain.updateMultiValue({
        exchange: OExchange[0],
        symbol: OSymbol[0],
        expiryDate: new Date(),
        depth: ODepth[1]
    });
    // tiggering the initialization apis
    getStrategies();
    getBrokerOrders(Brokers['IBKR']);
    // Delayed execution for below API, it has to be called after the trades updated from the websockets
    setTimeout(() => {
        getStrategyTrade();
        getStopLoss();
    }, 10000);
}
// Updates broker information based on authentication results
export const updateBrokerInfo = (result: Record<string, any>, accountID: string): void => {
    const { activeBroker = [], brokerInfo = {} } = broker;
    const currentBroker = Brokers['IBKR'];
    activeBroker.push(currentBroker);
    users.updateValue("account_id", accountID);
    brokerInfo[currentBroker] = { ...brokerInfo[currentBroker], accountID, ...result };
    broker.updateMultiValue({ 'loading': false, currentBroker, activeBroker, brokerInfo });
    initialization();
};

// Fetch broker orders from IBKR API
export const getBrokerOrders = async (currentBroker: string): Promise<boolean> => {
    const { brokerInfo = {} } = broker;
    const { accountID } = brokerInfo[currentBroker] || {};
    const functionUrl = `ibkrBrokerOrders`;
    const method = "GET";
    const params = `accountID=${accountID}&userID=${users.id}&broker=${currentBroker}`;
    const isBackground = true;

    try {
        const response = await callApi({ baseUrl, functionUrl, method, params, isBackground });
        return handleOrdersResponse(response);
    } catch (error) {
        console.error("API error: ", error);
        return false;
    }
};

// Handles the response from the broker orders API call
const handleOrdersResponse = (response: any): boolean => {
    const { status = false, result = {} } = response || {};
    if (status) {
        console.log("result => ", result);
        return true;
    }
    return false;
};

// Logout from IBKR
export const callIBKRLogout = async (): Promise<boolean> => {
    const functionUrl = `ibkr_logout/${users.id}`;
    const method = 'DELETE';

    try {
        const response = await callApi({ baseUrl, functionUrl, method });
        return handleLogoutResponse(response);
    } catch (error) {
        console.error("API error: ", error);
        return false;
    }
};

// Handles the response from the logout API call
const handleLogoutResponse = (response: any): boolean => {
    const { status = false, result = {} } = response || {};
    if (isObjectTruthy(result) && status) {
        updateLogoutState();
        return true;
    }
    return false;
};

// Updates state after logout
const updateLogoutState = (): void => {
    const { activeBroker = [], brokerInfo = {}, updateMultiValue } = broker;
    const brokers = activeBroker.filter((item) => item !== 'IBKR');
    delete brokerInfo['IBKR'];
    updateMultiValue({ currentBroker: '', activeBroker: brokers, brokerInfo });
    orders.reset();
    option_chain.reset();
    openInterest.reset();
    stopLoss.reset();
    targetProfits.reset();
};

// Fetch products
export const getProducts = async (requestBody: Record<string, any>): Promise<Record<string, any>> => {
    const functionUrl = `TpGetProducts`;
    try {
        const response = await callApi({ baseUrl, functionUrl, requestBody });
        return handleGetProductsResponse(response);
    } catch (error) {
        console.error("API error: ", error);
        return {};
    }
};

// Handles the response from the get products API call
const handleGetProductsResponse = (response: any): Record<string, any> => {
    const { status = false, result = {} } = response || {};
    if (status && Object.keys(result).length > 0) {
        return result;
    }
    return {};
};

// Trigger market data request
export const triggerMarketData = async (requestBody: Record<string, any>): Promise<boolean> => {
    const functionUrl = `TpTriggerMarket`;
    try {
        const response = await callApi({ baseUrl, functionUrl, requestBody });
        return handleMarketDataResponse(response);
    } catch (error) {
        console.error("API error: ", error);
        return false;
    }
};

// Handles the response from the market data trigger API call
const handleMarketDataResponse = (response: any): boolean => {
    const { status = false } = response || {};
    return status;
};

// Request market data
export const requestMarketData = async (params: string): Promise<Record<string, any>> => {
    const { currentBroker } = broker;
    const functionUrl = `TpRequestMarket`;
    const method = "GET";
    const urlValue = currentBroker;
    const isBackground = true;

    try {
        const response = await callApi({ baseUrl, functionUrl, method, urlValue, params, isBackground });
        return handleRequestMarketDataResponse(response);
    } catch (error) {
        console.error("API error: ", error);
        return {};
    }
};

// Handles the response from the request market data API call
const handleRequestMarketDataResponse = (response: any): Record<string, any> => {
    const { status = false, result = {} } = response || {};
    if (status && Object.keys(result).length > 0) {
        return result;
    }
    return {};
};

// Handles the response from the remove market data API call
const handleRemoveMarketDataResponse = (response: any): boolean => {
    const { status = false } = response || {};
    return status;
};

// Stop market data
export const stopMarketData = async (conids: string = ""): Promise<boolean> => {
    const { currentBroker } = broker;
    const functionUrl = `TpStopMarket`;
    const method = 'DELETE';
    let urlValue = `${currentBroker}?&userId=${users.id}`;
    if (conids) {
        urlValue = `${urlValue}+&conids=${conids}`;
    }

    try {
        const response = await callApi({ baseUrl, functionUrl, method, urlValue });
        return handleStopMarketDataResponse(response);
    } catch (error) {
        console.error("API error: ", error);
        return false;
    }
};

// Handles the response from the stop market data API call
const handleStopMarketDataResponse = (response: any): boolean => {
    const { status = false, result = {} } = response || {};
    return status && result.length > 0;
};