import React from "react";
import { observer } from "mobx-react";
import config from "./subscriber/Config";
import { isTruthy } from "./helpers/Helper";
import { CustomAlert, ModalWithSpinner } from "./components";
import { alertColor } from "./utils/constant";

const RootWapper: React.FC = observer(() => {
    const { loading, apiError, apiSuccess } = config;
    return (
        <>
            {loading &&
                <ModalWithSpinner show={loading} onClose={() => {console.log()}} message={"Loading..."} />
            }
            {isTruthy(apiError) && <CustomAlert type={alertColor.ERROR} message={apiError}/>}
            {isTruthy(apiSuccess) && <CustomAlert type={alertColor.SUCCESS} message={apiSuccess}/>}
        </>
    )
});

export default RootWapper;