import moment from "moment";
import { getArray, getArrayLength, getObjectLength, isArrayTruthy, isObjectTruthy, isTruthy } from "../../../helpers/Helper";
import marketDataStrategy from "../../../subscriber/MarketDataStrategy";
import deltaStrategies from "../../../subscriber/DeltaStrategies";
import openInterest from "../../../subscriber/OpenInterest";
import option_chain from "../../../subscriber/OptionChain";
import Analyze from "../../../subscriber/Analyze";
import orders from "../../../subscriber/Orders";

export const adjustToWeekday = (date: moment.Moment): moment.Moment => {
    const dayOfWeek = date.day();  // day() returns 0 for Sunday, 6 for Saturday

    // If it's Saturday (6), add 2 day to make it Monday
    if (dayOfWeek === 6) {
        return date.add(2, 'days'); // Adjust to Monday
    }
    // If it's Sunday (0), add 1 day to make it Monday
    else if (dayOfWeek === 0 ) {
        return date.add(1, 'days'); // Adjust to Monday
    }
    // If it's already a weekday, return the same date
    return date;
};

export const adjustToWeekdayFromDate = (date: Date): Date => {
    const dayOfWeek = date.getDay();  // getDay() returns 0 for Sunday, 6 for Saturday

    // If it's Saturday (6), add 2 days to make it Monday
    if (dayOfWeek === 6) {
        date.setDate(date.getDate() + 2); // Move to Monday
    }
    // If it's Sunday (0), add 1 day to make it Monday
    else if (dayOfWeek === 0) {
        date.setDate(date.getDate() + 1); // Move to Monday
    }
    
    // Return the adjusted (or same) date
    return date;
};

export const updateMarketData = (
    currentData: any[],
    newData: { [key: string]: any }
): any[] => {
    const updatedData: any[] = currentData; // Clone currentData to avoid mutating input

    Object.keys(newData).forEach(conid => {
        const index = updatedData.findIndex(item => item.conid === parseInt(conid));
        if (index !== -1) {
            // If `conid` exists in `currentData`, merge it
            updatedData[index] = { ...currentData[index], ...newData[conid] };
        } else {
            // If `conid` doesn't exist, add it as a new entry
            updatedData.push(newData[conid]);
        }
    });

    return updatedData;
};

export const mergeMarketAndProduct = (marketData: any[]): any[] => {
    const { products } = option_chain;
    const { products: strategiesProducts } = marketDataStrategy;

    return getArray(marketData).map((marketItem) => {
        if (!isObjectTruthy(marketItem)) return marketItem;
        const { conid: key } = marketItem || {};

        const productItem = getArray(products).find(product => Number(product.id) === Number(key));

        // Fix: Extract values from strategiesProducts (objects with expiry keys) & flatten
        const allStrategyProducts = Object.values(strategiesProducts).flat(); 
        
        // Find matching strategy product
        const strategyProItem = allStrategyProducts.find((strategy: any) => 
            Number(strategy.id) === Number(key)
        ) || {};
        
        return productItem
            ? { ...marketItem, ...productItem }
            : getObjectLength(strategyProItem) > 1
            ? { ...marketItem, ...strategyProItem }
            : marketItem;
    });
};


export const formatMarketData = (
    marketData: any[], 
    expiry: Date | null
): any[] => {
    if (!marketData || !Array.isArray(marketData)) return [];

    const result: Record<string, any> = {};
    for (const marketItem of marketData) {
        if (!marketItem || typeof marketItem !== "object") continue;

        const { conid, option_type, strike_price, expiry: productExpiry } = marketItem;
        if (!conid || !strike_price) continue;

        const prefix = option_type === "C" ? "call_" : "put_";
        const strikePrice = strike_price;

        if (!result[productExpiry]) {
            result[productExpiry] = {};
        }

        if (!result[productExpiry][strikePrice]) {
            result[productExpiry][strikePrice] = {};
        }

        // Create prefixed object keys
        const excludeKeys = ["id", "exchange", "token", "lot_size", "symbol", "trading_symbol", "expiry", "option_type", "instrument", "strike_price", "tick_size"];
        const prefixedMarket = Object.entries(marketItem).reduce((acc, [key, value]) => {
            if (!excludeKeys.includes(key)) {
                acc[prefix + key] = value;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {} as Record<string, any>);

        // Merge into the result
        result[productExpiry][strikePrice] = { ...result[productExpiry][strikePrice], ...prefixedMarket };
    }
    const filterByExpiry = result[moment(expiry).format("YYYY-MM-DD")] || {};
    return Object.values(filterByExpiry) || [];
};

export const updatePreOrder = (results: any, preOrderData: any) => {
    if (getObjectLength(preOrderData) < 0 || !preOrderData.conid) {
        return preOrderData;
    }

    for (let strike in results) {
        const strikeData = results[strike];

        if (strikeData.C && strikeData.C.conid === preOrderData.conid) {
            const match = strikeData.C;
            return { 
                ...preOrderData, 
                ask_size: match.ask_size, 
                ask_price: match.ask_price, 
                bid_size: match.bid_size, 
                bid_price: match.bid_price 
            };
        }

        if (strikeData.P && strikeData.P.conid === preOrderData.conid) {
            const match = strikeData.P;
            return { 
                ...preOrderData, 
                ask_size: match.ask_size, 
                ask_price: match.ask_price, 
                bid_size: match.bid_size, 
                bid_price: match.bid_price 
            };
        }
    }

    // If no match is found, return the original preOrderData
    return preOrderData;
}

export const getMarketByPId = (product_id: number) => {
    const { marketData = [] } = option_chain
    return getArray(marketData).find(item => item?.conid === product_id)
}

export const getProductById = (product_id: string) => {
    const { products = [] } = option_chain;
    return getArray(products).find(item => String(item?.id) === String(product_id));
}

export const get_unused_conids = () => {
    const { marketData } = option_chain
    const { positions, orderBookData } = orders;
    const { selectedStrategyLegs } = Analyze
    const unusedIds: any[] = []
    getArray(marketData).forEach((item: any) => {
        const position_con_id = getArray(positions).find(position => position.product_id === item.conid)
        const order_con_id = getArray(orderBookData).find(order => order.product_id === item.conid)
        const leg_con_id = getArray(selectedStrategyLegs).find(leg => Number(leg.product_id) === Number(item.conid))
        if (getObjectLength(position_con_id) === 0 && getObjectLength(order_con_id) === 0 && getObjectLength(leg_con_id) === 0) {
            unusedIds.push(item.conid)
        }
    })
    return unusedIds.join(',');
}

export const get_missing_strategy_ids = (): any[] => {
    const { marketData } = option_chain
    const { strategy_list_trades } = orders
    const unusedIds: any[] = []
    getArray(strategy_list_trades).forEach((item: any) => {
        const strategy_con_id = getArray(marketData).find(data => Number(data.conid) === Number(item.product_id))
        if (getObjectLength(strategy_con_id) === 0) {
            unusedIds.push(item.conid)
        }
    })
    return unusedIds;
}

export const get_open_interest_conids = () => {
    const { openInterestData } = openInterest;
    const unusedIds: any[] = []
    getArray(openInterestData).forEach((item: any) => {
        if(!moment(openInterestData).isSame(option_chain.expiryDate, 'day')) {
            unusedIds.push(item.call_conid);
            unusedIds.push(item.put_conid);
        }
    });
    return unusedIds.join(',');
}

export const get_used_conids = () => {
    const { positions, orderBookData } = orders;
    const usedIds: any[] = []
    getArray(positions).forEach(item => {
        usedIds.push(item.product_id)
    })
    getArray(orderBookData).forEach(item => {
        if(usedIds.includes(item.product_id)) {
            usedIds.push(item.product_id)
        }
    })
    return usedIds.join(',');
}