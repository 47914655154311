import { v4 as uuidv4 } from 'uuid';
import { TradeStatus, colorCode } from "../utils/constant";
import { GetLocalStorage, SetLocalStorage } from "./LocalStorage";
import { callIBKRLogout } from '../provider/Ibkr';

export function getArray(value: any) {
    try {
        // Attempt to convert the value to an array, handling potential errors
        const array = Array.isArray(value) ? value : [];
        return array;
    } catch (error) {
        console.error("Error converting value to array:", error);
        // Return an empty array in case of errors to prevent further issues
        return [];
    }
}

export function getObject(value: any) {
    try {
        // Attempt to return the value as an object if it's already a valid object
        return typeof value === 'object' && value !== null ? value : {};
    } catch (error) {
        // Catch any unexpected errors during object handling
        console.error("Error ensuring object:", error);
        // Return an empty object in case of errors to prevent further issues
        return {};
    }
}

export function getArrayLength(value: any[] | undefined) {
    return getArray(value).length;
}

export function getObjectLength(value: any) {
    return Object.keys(getObject(value)).length;
}

export function isArrayTruthy(value: any[] | undefined) {
    return getArray(value).length > 0;
}

export function isObjectTruthy(value: any) {
    return Object.keys(getObject(value)).length  > 0;
}

export const isString = (str: string | any, options: Record<string, any> = {}) => {
    // Basic checks:
    if (!str || typeof str !== "string") return false;
  
    // Additional validations based on options:
    if (options.minLength && str.length < options.minLength) return false;
    if (options.maxLength && str.length > options.maxLength) return false;
    if (options.requiredPattern && !options.requiredPattern.test(str)) return false;
    if (options.disallowedPattern && options.disallowedPattern.test(str)) return false;
  
    // Pass all checks:
    return true;
}

export const isTruthy = (value: any): boolean => {
    const falsyValues = [undefined, null, 0, false, '', NaN];  
    return !falsyValues.includes(value);
}

export const isFalsy = (value: any): boolean => {
    const falsyValues = [undefined, null, 0, false, '', NaN];  
    return falsyValues.includes(value);
}

export const isNull = (value: any): boolean => {
    const nullValue = ["", "0", "0.00", "0.000", 0, 0.0, 0.00, 0.000, null, undefined, NaN, "null", "undefined"]
    return nullValue.includes(value) || Number.isNaN(value);
}

export function SCREEN_WIDTH() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0;
    return width as number;
}

export function SCREEN_HEIGHT() {
    const height =  window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
    return height as number;
}

/**
 * Rounds down the price to the nearest value based on specified criteria.
 * - If the price is less than 10, it rounds down to the nearest 0.5.
 * - If the price is 10 or greater, it rounds down to the nearest 0.25.
 * 
 * @param price - The price to round down (any type).
 * @returns The rounded down price, or NaN if the input cannot be converted to a number.
 */
export const roundDownToNearest = (price: any): number => {
    // Convert the input to a number
    const numericPrice = Number(price);
  
    // Check if the conversion was successful
    if (isNaN(numericPrice)) {
        console.error('Invalid input: price must be a number or a string representing a number');
        return NaN; // Return NaN or handle this case as needed
    }
  
    // Rounding logic based on the value of the numeric price
    if (numericPrice < 10) {
        // Round down to the nearest 0.05 for prices less than 10
        return Math.floor(numericPrice * 20) / 20;
    } else {
        // Round down to the nearest 0.25 for prices 10 or greater
        return Math.floor(numericPrice * 4) / 4;
    }
};

export const getTableRowColor = (status: string | null | undefined): any => {
    const { White, Black, GOGreen, MidnightBlue, AlizarinRed } = colorCode;
    const actionMap = {
        [TradeStatus.OPEN]: {'background-color': White, 'color': Black},
        [TradeStatus.ACTIVE]: {'background-color': MidnightBlue, 'color': White},
        [TradeStatus.COMPLETE]: {'background-color': GOGreen, 'color': White},
        [TradeStatus.REJECT]: {'background-color': AlizarinRed, 'color': White},
    }
    return status && actionMap[status] ? actionMap[status] : {'background-color': White};
};

export const getDeviceId = () => {
    let device_id = uuidv4();
    const localDeviceId = GetLocalStorage('device_id', false);
    if(isTruthy(localDeviceId)) {
        device_id = localDeviceId;
    }
    SetLocalStorage('device_id', device_id, 15552000, false)
    return device_id;
}

export const getUserId = () => {
    let user_id = null;
    const localUserId = GetLocalStorage('user_id', true);
    if(isTruthy(localUserId)) {
        user_id = localUserId;
    }
    return user_id;
}

export const getupdateColor = (lastValue:string | number, currentValue:string | number): string => {
    const {GOGreen, AlizarinRed} = colorCode
    return lastValue > currentValue ? GOGreen : AlizarinRed;
}

export function useHandleErrorByCode(errorCode: number) {
    const error: Record<number, () => void> = {
        101: () => callIBKRLogout()
    };

    if (error[errorCode]) {
        error[errorCode]();
    }
}

export const updatedOptionType = (option_type: string) => {
    return option_type && option_type === 'P' ? "PUT" : "CALL"
}