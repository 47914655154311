import { makeAutoObservable } from 'mobx';
import { getObject } from '../helpers/Helper';
import { makePersistable } from 'mobx-persist-store';

interface IValues {
    [key: string]: any;
}

class Broker {
    loading: boolean = false;
    currentBroker: string = 'IBKR'
    activeBroker: any[] = [];
    brokerInfo: any = {}

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'BrokerStore',
            properties: ['activeBroker', 'brokerInfo', 'currentBroker'],
            storage: localStorage
        });
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        Object.keys(getObject(values)).forEach((key) => {
            (this as any)[key] = values[key];
        });
    }

    reset = () => {
        this.loading = false;
        this.activeBroker = [];
        this.brokerInfo = {};
        this.currentBroker = ''
    }
}
const broker = new Broker();
export default broker;
