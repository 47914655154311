import React from 'react';

interface ModalWithSpinnerProps {
  show: boolean;
  onClose: () => void;
  message?: string;
}

export const ModalWithSpinner: React.FC<ModalWithSpinnerProps> = ({ show, onClose, message }) => {
    
    return (
        <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        {message && <p>{message}</p>}
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade ${show ? 'show' : ''}`} onClick={onClose}></div>
        </div>
    );
};
