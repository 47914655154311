import React, { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import NotFound from "../screens/NotFound";
import Config from "../subscriber/Config";
import { observer } from "mobx-react";
import { isTruthy } from "../helpers/Helper";
import ErrorPage from "./Common/ErrorPage";

// const IBKR = lazy(() => import("../screens/Broker/IBKR"));
// const Demo = lazy(() => import("../screens/Demo"));
const Login = lazy(() => import("../screens/Login/Login"));
const Registration = lazy(() => import("../screens/Registration/Registration"));
const Dashboard = lazy(() => import("../screens/Dashboard/Dashboard"));
const Brokers = lazy(() => import("../screens/Broker/Brokers"));
const IBKRSetUp = lazy(() => import("../screens/Broker/IBKRSetUp"));
const Orders = lazy(() => import("../screens/Orders/Orders"));
const Analyze = lazy(() => import("../screens/Analyze/Analyze"));
const Users = lazy(() => import("../screens/Users/Users"));

const payoffChat = lazy(() => import("../test/PayoffChart"))

interface RouteProps {
    component: React.ComponentType;
    auth: boolean
}
const PublicRoute: React.FC<RouteProps> = ({ component: Component, auth }) => {
    return auth ? <Navigate to="/dashboard" replace /> : <Component />;
};

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, auth }) => {
    return auth ? <Component /> : <Navigate to="/login" replace />;
};

export const Navigation: React.FC = observer(() => {
    const auth = isTruthy(Config.authorization);
    return (
        <BrowserRouter>
            <ErrorPage />
            <Routes>
                <Route path="/" element={<PublicRoute component={Login} auth={auth} />} />
                <Route path="/login" element={<PublicRoute component={Login} auth={auth} />} />
                <Route path="/registration" element={<PublicRoute component={Registration} auth={auth} />} />
                <Route path="/dashboard" element={<PrivateRoute component={Dashboard} auth={auth} />} />
                <Route path="/users" element={<PrivateRoute component={Users} auth={auth} />} />
                <Route path="/user/add" element={<PrivateRoute component={Dashboard} auth={auth} />} />
                <Route path="/brokers" element={<PrivateRoute component={Brokers} auth={auth} />} />
                <Route path="/ibkr/ibkr_setup" element={<PrivateRoute component={IBKRSetUp} auth={auth} />} />
                <Route path="/orders" element={<PrivateRoute component={Orders} auth={auth} />} />
                <Route path="/analyze" element={<PrivateRoute component={Analyze} auth={auth} />} />
                <Route path="/test" element={<PublicRoute component={payoffChat} auth={auth} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
});