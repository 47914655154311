import moment from "moment";
import { callApi } from "../helpers/Api";
import { baseUrl } from "../helpers/Config";
import { getArray, getObject, getObjectLength, isArrayTruthy, isObjectTruthy } from "../helpers/Helper";
import { create_product_dec } from "../screens/Orders/helpers/Helper";
import config from "../subscriber/Config";
import orders from "../subscriber/Orders";
import users from "../subscriber/Users";
import broker from "../subscriber/Broker";
import { getColor } from "../utils/constant";
import stopLoss from "../subscriber/StopLoss";

export const getOrders = () => {
    const { currentBroker } = broker;
    const functionUrl = `TPGetUserOrder`;
    const requestBody = {};
    const method = 'GET';
    const params = `userID=${users.id}&broker=${currentBroker}`;
    const isBackground = true
    callApi({baseUrl, functionUrl, requestBody, method, params, isBackground})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        if (isArrayTruthy(result) && status && result.length > 0) {
            const updatedRes = transformedResponse(result);
            orders.updateMultiValue({
                'orderBookData': updatedRes,
                'ordersData': result
            });
            return true;
        }
        orders.updateMultiValue({
            'orderBookData': [],
            'ordersData': []
        });
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const getTrades = () => {
    const { currentBroker } = broker;
    const functionUrl = `TPGetTrades`;
    const requestBody = {};
    const method = 'GET';
    const params = `userID=${users.id}&broker=${currentBroker}`;
    const isBackground = true

    callApi({baseUrl, functionUrl, requestBody, method, params, isBackground})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        if (isArrayTruthy(result) && status && result.length > 0) {
            const updatedRes = transformedTrades(result);
            orders.updateMultiValue({
                'trades': updatedRes
            });
            return true;
        }
        orders.updateMultiValue({
            'trades': []
        });
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const getPositions = () => {
    const { currentBroker } = broker;
    const functionUrl = `TPGetPosition`;
    const requestBody = {};
    const method = 'GET';
    const params = `userID=${users.id}&broker=${currentBroker}`;
    const isBackground = true

    callApi({baseUrl, functionUrl, requestBody, method, params, isBackground})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        if (isObjectTruthy(result) && status && getObjectLength(result) > 0) {
            const updatedRes = transformedPositions(result);
            orders.updateMultiValue({
                'positions': updatedRes
            });
            return true;
        }
        orders.updateMultiValue({
            'positions': []
        });
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const createOrder = async (requestBody: Record<string, any>):Promise<any> => {
    const { currentBroker, brokerInfo } = broker;
    const functionUrl = `TPCreateOrder`;
    const method = 'POST'
    const urlValue = getObject(brokerInfo)[currentBroker]?.accountID || "";
    
    orders.updateValue('loading', true);
    return callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        orders.updateValue('loading', false);
        if (isObjectTruthy(result) && status && result.length > 0) {
            const { id: replayOrderID = '', message = [] } = result[0];
            const replayMessage = message.length > 0 ? message[0] : '';
            orders.updateMultiValue({
                orderDialog: false,
                replay: replayMessage ? true : false,
                replayOrderID,
                replayMessage,
            });
            if(!replayMessage) {
                stopLoss.updateValue("isOrderApirunning", false);
            }
            return true;
        }else {
            config.updateValue("apiError", result?.error)
        }
        stopLoss.updateValue("isOrderApirunning", false);
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const createBulkOrder = (requestBody: Record<string, any>): Promise<boolean> => {
    const { currentBroker, brokerInfo } = broker;
    const functionUrl = `TPCreateBulkOrder`;
    const method = 'POST'
    const urlValue = getObject(brokerInfo)[currentBroker]?.accountID || "";

    orders.updateValue('loading', true);
    return callApi({baseUrl, functionUrl, requestBody, method, urlValue}).then((response: any) => {
        const { status = false, result = '' } = response || {}
        orders.updateValue('loading', false);
        if(status) {
            config.updateValue('apiSuccess', result);
            return true;
        }
        return false;
    }).catch(error => {
        console.error("API error: ", error);
        return false;
    });
}

export const replayOrder = () => {
    const { currentBroker } = broker;
    const functionUrl = `TPReplayOrder`;
    const requestBody = {
        "confirmed": true,
        "broker": currentBroker,
        "user_id": users.id
    }
    const method = 'POST'
    const urlValue = getObject(orders).replayOrderID
    orders.updateValue('loading', true);
    callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        orders.updateValue('loading', false);
        if (isObjectTruthy(result) && status) {
            let popupStatus = true;
            let replayOrderID = '';
            let replayMessage = '';
            let successMessage = '';
            if(result.length > 0) {
                if(result[0].order_status) {
                    successMessage = `Your Order submited and curent order status ${result[0].order_status}`;
                    popupStatus = false;
                    getOrders();
                }else {
                    const { message = [] } = result[0];
                    replayOrderID = result[0]['id'];
                    replayMessage = message.length > 0 ? message[0] : '';
                }
            }else {
                successMessage = `Your Order submited and curent order status ${result.order_status}`;
                popupStatus = false;
                getOrders();
            }
            if(!replayOrderID) {
                stopLoss.updateValue("isOrderApirunning", false);
            }
            orders.updateMultiValue({
                replay: popupStatus,
                replayOrderID,
                replayMessage,
            });
            config.updateValue('apiSuccess', successMessage);
            return true;
        }
        stopLoss.updateValue("isOrderApirunning", false);
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const updateOrders = (requestBody: Record<string, any>) => {
    const { currentBroker, brokerInfo } = broker;
    const functionUrl = `TPUpdateOrder`;
    const method = 'PUT';
    const urlValue = getObject(brokerInfo)[currentBroker]?.accountID || "";
    orders.updateValue('loading', true);
    callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        orders.updateValue('loading', false);
        if (isObjectTruthy(result) && status && result.length > 0) {
            orders.updateMultiValue({
                updateDialog: false,
                selectedOrder: {}
            });
            config.updateValue('apiSuccess', 'Your Order updated successfully!!!');
            getOrders();
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const cancelOrder = (orderId?: string | null) => {
    const { currentBroker, brokerInfo } = broker;
    orders.updateValue('loading', true);
    const functionUrl = `TPCancelOrder`;
    const requestBody = {
        "confirmed": true,
        "broker": currentBroker
    }
    const method = 'DELETE';
    const { accountID = '' } = getObject(brokerInfo)[currentBroker];
    const urlValue = `${accountID}?orderID=${orderId ? orderId : getObject(orders).replayOrderID}&broker=${currentBroker}`

    callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        orders.updateMultiValue({
            loading: false,
            cancelDialog: false
        });
        if (isObjectTruthy(result) && status) {
            orders.updateMultiValue({
                replay: false,
                replayOrderID: '',
                replayMessage: '',
            });
            getOrders();
            config.updateValue('apiSuccess', result.msg);
            return true;
        }
        config.updateValue('apiError', result);
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}
/**
 * triggering the order status based on order id
 * @param order_id 
 */
export const trigger_status = (order_id: string) => {
    const { currentBroker } = broker;
    
    const functionUrl = `tpTriggerOS`;
    const requestBody = {
        "broker": currentBroker,
        "order_id": order_id,
        "user_id": users.id,
    }
    const method = 'POST'
    const isBackground = true
    callApi({baseUrl, functionUrl, requestBody, method, isBackground})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        if (isObjectTruthy(result) && status) {
            
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}
/**
 * request order status
 * @param order_id 
 */
export const request_status = (order_ids: string) => {
    const { currentBroker } = broker;
    const functionUrl = `tpRequestOS`;
    const method = 'GET'
    const urlValue = currentBroker;
    const params = `orderId=${order_ids}`;
    const isBackground = true;
    callApi({baseUrl, functionUrl, method, urlValue, params, isBackground})
    .then((response: any) => {
        const { status = false, result = [] } = response || {}
        if (isArrayTruthy(result) && status) {
            const { orderBookData = [] } = orders;
            const updatedValue = updateDataById(orderBookData, result);
            const updatedRes = transformedResponse(updatedValue);
            orders.updateMultiValue({
                'orderBookData': updatedRes,
                'ordersData': result
            });
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const stop_status = (order_id: string) => {
    const { currentBroker } = broker;
    const functionUrl = `tpStopOS`;
    const method = 'DELETE';
    const urlValue = `${currentBroker}?orderId=${order_id}`;
    const isBackground = true
    try {
        callApi({ baseUrl, functionUrl, method, urlValue, isBackground }).then((response: any) => {
            const { status = false, result = {} } = response || {};
            if (status) {
                return result;
            }
            return false;
        });
    } catch (error) {
        console.error("API error: ", error);
        return false;
    }
}

const updateDataById = (originalData: any[], updatedValues: any[]) => {
    return getArray(originalData).map(item => {
        const updatedItem = getArray(updatedValues).find(updatedValue => updatedValue.id === item.id);
        return updatedItem ? { ...item, ...updatedItem } : item;
    });
};

const transformedResponse = (result: any[]): any[] => {
    return getArray(result).map((order: any) => {
        const { traded_product } = order;
        const { lot_size } = traded_product
        return {
            id: order.id,
            order_id: order.order_id,
            exchange: order.exchange,
            client_id: order.user.email.split('@')[0],
            users: order.user.email.split('@')[0],
            symbol: order.symbol,
            expiry_date: moment(order.expiry_date).format('MMMDD, YY'),
            option_type: order.trade_type,
            side: {"side": order.side.charAt(0).toUpperCase() + order.side.slice(1).toLowerCase(), "colorCode": getColor(order.side)},
            quantity: { "quantity": order.quantity, "colorCode": getColor(order.side)},
            price: order.price,
            notional: (order.quantity * lot_size) * order.price,
            trigger_price: order.price,
            trade_quantity: order.filled_quantity || 0,
            average_price: order.price - (order.price * 0.1),
            broker: order.broker.name,
            status: order.status.toLowerCase(),
            remarks: order.remarks,
            product_id: order.product_id,
            product_dec: create_product_dec(traded_product),
            traded_product: traded_product
        }
    });
}

const transformedTrades = (result: any[]): any[] => {
    return result.map((trade: any) => {
        const { traded_product  } = trade;

        return {
            ...trade,
            "product_dec": create_product_dec(traded_product)
        };
    });
}

const transformedPositions = (result: any[]): any[] => {
    return result.map((position: any) => {
        const { product_id, traded_product  } = position;
        return {
            ...position,
            "id": product_id,
            "product_dec": create_product_dec(traded_product)
        };
    });
}