import { callApi } from "../helpers/Api";
import { decryptWithPrivateKey, generateKeys, objectDecrypt, parseJwt } from "../helpers/Crypto";
import { getDeviceId, getUserId, isObjectTruthy, isString } from "../helpers/Helper";
import { baseUrl } from "../helpers/Config";
import { updateBrokerInfo } from "./Ibkr";
import Config from "../subscriber/Config";
import Users from "../subscriber/Users";
import users from "../subscriber/Users";
import config from "../subscriber/Config";
import broker from "../subscriber/Broker";

export const init = () => {
    let { privateKey, publicKey } = generateKeys()
    publicKey = publicKey.replace('-----BEGIN RSA PUBLIC KEY-----', '');
    publicKey = publicKey.replace('-----END RSA PUBLIC KEY-----', '').trim();
    const device_id = getDeviceId();
    const user_id = getUserId();
    const functionUrl = 'init';
    const requestBody = { public_key: publicKey, device_id: device_id, user_id: user_id };
    try {
        callApi({ baseUrl, functionUrl, requestBody}).then(async (response: Record<string, any>) => {
            const { status = false, result = {} } = response || {}
            if (isObjectTruthy(result) && status) {
                const { apiEncryptionKey, deviceEncryptionKey, requestId } = result;
                const apiKey = await decryptWithPrivateKey(privateKey, apiEncryptionKey);
                let apiData:any = {};
                if (isString(deviceEncryptionKey)) {
                    apiData['device_encryption_key'] = await decryptWithPrivateKey(privateKey, deviceEncryptionKey);
                }
                apiData['api_encryption_key'] = apiKey;
                apiData['request_id'] = requestId;
                Config.updateMultiValue(apiData);
            }
        });
    } catch (error) {
        console.log('Error from catch => ', error);
    }
};

export const auth = async (requestBody: Record<string, any>): Promise<Record<string, any>> => {
    return processAuthentication('auth', requestBody);
};

export const registration = async (requestBody: Record<string, any>): Promise<Record<string, any>> => {
    return processAuthentication('register', requestBody);
};

/**
 * login & registration common api call
 * @param functionUrl 
 * @param requestBody 
 * @returns 
 */
const processAuthentication = async (functionUrl: string, requestBody: Record<string, any>): Promise<Record<string, any>> => {
    try {
        const response: Record<string, any> = await callApi({ baseUrl, functionUrl, requestBody });
        const { status = false, result = {} } = response || {};

        if (isObjectTruthy(result) && status) {
            await handleApiResponse(result);
            return { status: true, error: '' };
        }

        return { status: false, error: isString(result) ? result : 'Oops! something went wrong. Please try again later.' };
    } catch (error) {
        console.error("Error from Catch => ", error);
        return { status: false, error: 'Oops! An error occurred. Please contact customer care for help.' };
    }
};

const handleApiResponse = async (result: Record<string, any>) => {
    const { sessionId, deviceEncryptionKey, apiEncryptionKey, token, user, broker_info = {} } = result;
    const { account_name, role_id } = user;
    const { accountID } = broker_info;

    role_id !== 1 && broker.updateValue('currentBroker', account_name);
    role_id === 1 && updateBrokerInfo(broker_info, accountID);

    const data = await objectDecrypt({
        'session_id': sessionId,
        'device_encryption_key': deviceEncryptionKey,
        'api_encryption_key': apiEncryptionKey
    });

    Config.updateMultiValue({
        ...data,
        'authorization': token
    });
    const decodedPayload = parseJwt(token);
    const subAsString = decodedPayload.sub.replace(/'/g, '"');
    const subAsJson = JSON.parse(subAsString);

    user['id'] = subAsJson.id;
    user['email'] = subAsJson.email;
    Users.updateMultiValue(user);
};

export const logoutUser = (requestBody = {}): Promise<boolean> => {
    const functionUrl = `logout`;
    const method = 'POST';
    const urlValue = users.id

    return callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false } = response || {};
        if (status) {
            config.reset();
            users.reset();
            // reset all the subscriber
            // call the inti api for login
            init()
            return true
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
        return false
    });
}