import { callApi } from "../helpers/Api";
import { baseUrl } from "../helpers/Config";
import { getArray, isObjectTruthy } from "../helpers/Helper";
import { createStrategyPositions, formedStrategyTrade } from "../screens/Analyze/helpers/Helpers";
import Analyze from "../subscriber/Analyze";
import config from "../subscriber/Config";
import orders from "../subscriber/Orders";

export const createVirtualOrder = (requestBody: Record<string, any>):Promise<any> => {
    const functionUrl = `AddVirtualOrders`;
    const method = 'POST'
    return callApi({baseUrl, functionUrl, requestBody, method})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        if (result && status) {
            orders.updateMultiValue({
                orderDialog: false
            });
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const executeVirtualOrder = (requestBody: Record<string, any>):Promise<any> => {
    const functionUrl = `ExecuteVirtualOrders`;
    const method = 'POST'
    return callApi({baseUrl, functionUrl, requestBody, method})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        orders.updateValue('loading', false);
        if (isObjectTruthy(result) && status && result.length > 0) {
            const { id: replayOrderID = '', message = [] } = result[0];
            const replayMessage = message.length > 0 ? message[0] : '';
            Analyze.updateValue("isModify", false)
            orders.updateMultiValue({
                replay: replayMessage ? true : false,
                replayOrderID,
                replayMessage,
            });
            deleteVirtualOrder(requestBody.ids)
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const deleteVirtualOrder = (order_ref: string) => {
    const functionUrl = `DeleteVirtualOrder`;
    const requestBody = {};
    const method = 'DELETE';
    const urlValue = order_ref;
    return callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = "" } = response || {}
        if (status) {
            config.updateValue("apiSuccess", result)
            const { selected_strategy } = Analyze
            const { strategy_list_trades } = orders
            const trades: any = getArray(strategy_list_trades).filter(
                (item) => item.order_ref !== order_ref
            );

            const updatedRes = formedStrategyTrade(trades);
            const strategyPositions = createStrategyPositions(updatedRes, selected_strategy?.value);
            orders.updateMultiValue({
                "strategy_list_trades": trades,
                "strategy_trades": updatedRes,
                "strategy_positions": strategyPositions
            });
            return true
        }
        return false
    }).catch(error => {
        console.error("API error getStrategies: ", error);
    });
}