import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Tooltip,
} from '@mui/material';
import moment from 'moment';
import { SCREEN_HEIGHT, getArray, getArrayLength, isNull, isTruthy } from '../../../helpers/Helper';
import { colorCode, statusColor } from '../../../utils/constant';
import './CustomTableStyle.css'
import { formatCurrency } from '../../../utils/utilities';

interface Data {
    id: number;
    [key: string]: any;
}

interface Column {
    id: keyof Data;
    label: string;
    numeric?: boolean;
    hidden?: boolean;
    price?: boolean;
    visible?: boolean;
}

interface TableHelperProps {
    tableKey: string;
    headers: Column[];
    data: Data[];
    enableCheckbox: boolean;
    dense?: boolean;
    rowClickStatus?: boolean;
    onRowClick?: (data: Data) => void;
    onCheckboxClick?: (data: Data) => void;
    onSelectAllClick?: (ids: any[]) => void;
    customActions?: React.ReactNode;
    tableHeight?: number;
    isExpiry?: boolean
}
const tableCellStyle = {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

const NoRecordStyle = {
    fontSize: '12px',
    color: colorCode.PaleTaupeGray
};

export const UITableHelper: React.FC<TableHelperProps> = ({
    tableKey,
    headers,
    data,
    enableCheckbox = false,
    rowClickStatus = false,
    dense = false,
    onRowClick,
    onCheckboxClick,
    onSelectAllClick,
    customActions,
    tableHeight = SCREEN_HEIGHT() * 0.3,
    isExpiry = false
}) => {
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>();
    const [selected, setSelected] = useState<number[]>([]);
    const [columns, setColumns] = useState<Column[]>(headers);
    const [open, setOpen] = useState(false);

    const { PaleTaupeGray } = colorCode

    const handleRequestSort = (property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: keyof Data) => () => {
        handleRequestSort(property);
    };

    const sortData = (data: Data[]) => {
        if (!orderBy) return data;
        return data.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
        if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = getArray(data).filter(n => !isNull(n["avg_quantity"])).map((n) => n.id);
            setSelected(newSelecteds);
            onSelectAllClick?.(newSelecteds);
        } else {
            setSelected([]);
            onSelectAllClick?.([]);
        }
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        onCheckboxClick?.(data.find(d => d.id === id)!);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    
    const getRowColor = (row: any) => {
        const statusKeys = ['status', 'orderStatus'];
        for (let key of statusKeys) {
            if (key in row) {
                const color = statusColor(row[key]);
                if (color) {
                    return { "backgroundColor": color };
                }
            }
        }
        if (isExpiry) {
            const { traded_product = {} } = row
            const { expiry } = traded_product
            if (moment(expiry).isBefore(moment(), 'day')) {
                return {"backgroundColor": PaleTaupeGray + "33"}
            }
        }
        return {};
    };

    const getAvailableColumns = () => {
        return getArray(columns).reduce((total, item) => {
            const { hidden = false, visible = true } = item;
            if (!hidden && visible) {
                return total + 1;
            }
            return total;
        }, 0);
    };

    const handleColumnVisibilityChange = (id: keyof Data) => {
        setColumns((prevColumns) =>
            prevColumns.map((column) =>
                column.id === id ? { ...column, visible: !column.visible } : column
            )
        );
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const isObjectWithColorCode = (value: any) => {
        return typeof value === 'object' && value !== null && typeof value.colorCode === 'string' && value.colorCode.trim() !== '';
    };

    const renderColumnVisibilityToggles = () => (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Toggle Column Visibility</DialogTitle>
            <DialogContent>
                {columns.map((column) => {
                    if (column.hidden) { return null; }
                    return (
                        <FormControlLabel
                            key={column.id}
                            control={
                                <Checkbox
                                    checked={isTruthy(column.visible)}
                                    onChange={() => handleColumnVisibilityChange(column.id)}
                                />
                            }
                            label={column.label}
                        />
                    )}
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Box key={tableKey} sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <div className='d-flex flex-row justify-content-end px-3'>
                    {isTruthy(enableCheckbox) && customActions && customActions}
                    <Tooltip title="View Table columns">
                        <IconButton className="p-0" onClick={handleClickOpen}>
                            <i className="bi bi-eye-fill"></i>
                        </IconButton>
                    </Tooltip>
                </div>
                {renderColumnVisibilityToggles()}
                <TableContainer sx={{ maxHeight: tableHeight }}>
                    <Table key={tableKey} sx={{ height: '100px' }} aria-label="customized table" size={isTruthy(dense) ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow>
                                {isTruthy(enableCheckbox) && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            indeterminate={selected.length > 0 && selected.length < data.length}
                                            checked={data.length > 0 && selected.length === data.length}
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                )}
                                {getArray(columns).map((header) => {
                                    if (!header.visible || header.hidden) { return null; }
                                    return (
                                        <TableCell
                                            key={header.id}
                                            align={header.numeric ? 'right' : 'left'}
                                            sortDirection={orderBy === header.id ? order : false}
                                            sx={{ ...tableCellStyle }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === header.id}
                                                direction={orderBy === header.id ? order : 'asc'}
                                                onClick={createSortHandler(header.id)}
                                            >
                                                {header.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getArray(sortData(data)).map((row, index) => {
                                const rowColor = getRowColor(row);
                                return (
                                    <TableRow
                                        hover
                                        onClick={() => {
                                            if (rowClickStatus) {
                                                onRowClick?.(row)
                                            }
                                        }}
                                        key={isTruthy(row.id) ? row.id : index}
                                        selected={isSelected(row.id)}
                                        sx={{ cursor: 'pointer' }}
                                        style={rowColor}
                                    >
                                        {enableCheckbox && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isSelected(row.id)}
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    disabled={isNull(row["avg_quantity"]) ? true : false}
                                                />
                                            </TableCell>
                                        )}
                                        {columns.map((header) => {
                                            if (!header.visible || header.hidden) { return null; }
                                            return (
                                                <>
                                                    {typeof row[header.id] === 'object' && isObjectWithColorCode(row[header.id]) ? (
                                                        <TableCell key={header.id} align={header.numeric ? 'right' : 'left'} sx={{ ...tableCellStyle, color: row[header.id]['colorCode'] }}>
                                                            {header.price ? formatCurrency(row[header.id][header.id]) : row[header.id][header.id]}
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell key={header.id} align={header.numeric ? 'right' : 'left'} sx={{ ...tableCellStyle }}>
                                                            {header.price ? formatCurrency(row[header.id]) : row[header.id]}
                                                        </TableCell>
                                                    )}
                                                </>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                            {getArrayLength(sortData(data)) === 0 &&
                                <TableRow>
                                    <TableCell colSpan={getAvailableColumns()}>
                                        <Typography className='d-flex justify-content-center' style={NoRecordStyle}>No Records Found</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};
