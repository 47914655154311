import React from 'react';

interface SpinnerProps {
    small?: boolean;
    style?: React.CSSProperties;
}

export const Spinner: React.FC<SpinnerProps> = ({ small = false, style }) => {
    const spinnerClass = `spinner-border ${small ? 'spinner-border-sm' : ''}`;

    return (
        <div className={spinnerClass} role="status" style={style}>
            <span className="visually-hidden">Loading...</span>
        </div>
    );
};
