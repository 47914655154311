import { makeAutoObservable } from 'mobx';
import { getObject } from '../helpers/Helper';

interface IValues {
    [key: string]: any;
}

export interface ExpiryMap {
    [key: string]: { expiryDate: string };
}

class MarketDataStrategy {
    expiryDate: Record<string, Date> = {}; 
    marketData: Record<string, any[]> = {};
    products: Record<string, any[]> = {};
    future: Record<string, any[]> = {};
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        Object.keys(getObject(values)).forEach((key) => {
            (this as any)[key] = values[key];
        });
    }

    reset = () => {
        this.expiryDate = {};
        this.marketData = {};
        this.products = {};
        this.future = {};
        this.loading=false;
    }
}
const marketDataStrategy = new MarketDataStrategy();
export default marketDataStrategy;
