import React from "react";
import { Breadcrumbs, Divider, Link, Stack, Typography } from "@mui/material";
import { getArray } from "../../../helpers/Helper";

interface CoustomBreadcrumbsProps {
    menu: any;
}

export const CoustomBreadcrumbs: React.FC<CoustomBreadcrumbsProps> = ({menu}) => {

    const breadcrumbs = getArray(menu).map((item, index) => {
        const isLast = index === getArray(menu).length - 1;
    
        return isLast ? (
            <Typography key={index} color="text.primary">
                {item.title}
            </Typography>
        ) : (
            <Link
                key={index}
                underline="hover"
                color="inherit"
                href={`/${item.link}`}
            >
                {item.title}
            </Link>
        );
    });

    return (
        <Stack spacing={2}>
            <Breadcrumbs
                separator={<i className="bi bi-chevron-right"></i>}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Divider />
        </Stack>
    )
}