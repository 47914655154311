import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import config from '../subscriber/Config';

export const generateKeys = () => {
    const crypt = new JSEncrypt({default_key_size: 2048 as any});
    const privateKey = crypt.getPrivateKey();
    const publicKey = crypt.getPublicKey();
  
    if (typeof privateKey === 'string' && typeof publicKey === 'string') {
        return { privateKey, publicKey };
    } else {
        return { privateKey: '', publicKey: '' };
    }
};

export const decryptWithPrivateKey = (privateKey: string, encryptedMessage: string) => {
    try {
        const crypt = new JSEncrypt();
        crypt.setPrivateKey(privateKey);
        const decryptedMessage = crypt.decrypt(encryptedMessage);
        return decryptedMessage ? decryptedMessage.trim() : null;
    } catch (error) {
        console.error("Decryption error:", error);
        return null; // Or handle the error as needed
    }
};

export const encryptData = (text: string) => {
    try {
        const { api_encryption_key: passPhrase } = config;
        const salt = CryptoJS.lib.WordArray.random(128 / 8);
        const iv = CryptoJS.lib.WordArray.random(128 / 8);

        // Derive key using PBKDF2 and passphrase
        const key = CryptoJS.PBKDF2(passPhrase, salt, {
            keySize: 256 / 32,  // Corresponds to the key size used in Python
            iterations: 100000  // Ensure this matches the iteration count used in Python
        });

        // Encrypt the text
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });

        // Concatenate IV, salt, and encrypted data (all Base64 encoded)
        const encrptMessage = iv.toString(CryptoJS.enc.Base64) + "::" +
               salt.toString(CryptoJS.enc.Base64) + "::" +
               encrypted.ciphertext.toString(CryptoJS.enc.Base64);
        return encrptMessage
    } catch (error) {
        console.error("Encryption error:", error);
        return null;
    }
};

export const decryptData = (encryptedData: string) => {
    try {
        const { api_encryption_key: passPhrase } = config;
        // Split the data into IV, salt, and ciphertext
        const parts = encryptedData.split('::');
        if (parts.length !== 3) {
            throw new Error('Invalid encrypted data format');
        }

        const iv = CryptoJS.enc.Base64.parse(parts[0]);
        const salt = CryptoJS.enc.Base64.parse(parts[1]);
        const ciphertext = parts[2];

        // Derive key using PBKDF2 and passphrase
        const key = CryptoJS.PBKDF2(passPhrase, salt, {
            keySize: 256 / 32,  // Corresponds to the key size used in Python
            iterations: 100000  // Ensure this matches the iteration count used in Python
        });

        // Decrypt the text
        const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        });
        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        return decryptedText.trim();
    } catch (error) {
        console.error("Decryption error:", error);
        return null;
    }
};

export const objectEncrypt = async (data: Record<string, any>): Promise<Record<string, any>> => {
    if (typeof data === 'object' && data !== null) {
        const encryptObjectData: Record<string, any> = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                encryptObjectData[key] = await encryptData(data[key]);
            }
        }
        return encryptObjectData;
    }
    return data;
};

export const objectDecrypt = async (data: Record<string, any>): Promise<Record<string, any>> => {
    if (typeof data === 'object' && data !== null) {
        const decryptObjectData: Record<string, any> = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                decryptObjectData[key] = await decryptData(data[key]);
            }
        }
        return decryptObjectData;
    }
    return data;
};

export const LocalStoreEncryption = (text: string): string | null => {
    try {
        const { device_encryption_key } = config;
        const iv = CryptoJS.lib.WordArray.random(128 / 8); // 128-bit IV
        const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(device_encryption_key), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return iv.toString(CryptoJS.enc.Hex) + ':' + encrypted.toString(); // Concatenate IV and ciphertext
    } catch (error) {
        console.error("Encryption error:", error);
        return null;
    }
};

export const LocalStoreDecryption = (encryptedData: string): string | null => {
    try {
        const { device_encryption_key } = config;
        const parts = encryptedData.split(':');
        const iv = CryptoJS.enc.Hex.parse(parts[0]);
        const encryptedText = parts[1];
        const bytes = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(device_encryption_key), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error("Decryption error:", error);
        return null;
    }
};

export const parseJwt = (token: string) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return null;
    }
}