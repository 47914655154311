import { makeAutoObservable } from 'mobx';
import { getObject } from '../helpers/Helper';
import { makePersistable } from 'mobx-persist-store';

interface IValues {
    [key: string]: any;
}

class Orders {
    ordersData: any[] = [];
    positions: any[] = [];
    trades: any[] = [];
    create: boolean = false;
    replay: boolean = false;
    replayOrderID: string = '';
    replayMessage: string = '';
    successMessage: string = '';
    orderBookData: any[] = [];
    orderDialog: boolean = false;
    updateDialog: boolean = false;
    cancelDialog: boolean = false;
    preOrder:Record<string, any> = {};
    selectedOrder: Record<string, any> = {};
    loading: boolean = false;
    positionPriceType: string = ''
    strategy_list_trades: any[] = [];
    strategy_trades: any = {};
    strategy_positions: any[] = [];

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'OrdersStore',
            properties: ['create','replay', 'trades'],
            storage: localStorage
        });
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        Object.keys(getObject(values)).forEach((key) => {
            (this as any)[key] = values[key];
        });
    }

    reset = () => {
        this.ordersData = [];
        this.positions = [];
        this.trades = [];
        this.create = false;
        this.replay = false;
        this.replayOrderID = '';
        this.replayMessage = '';
        this.successMessage = '';
        this.orderBookData = [];
        this.orderDialog = false;
        this.updateDialog = false;
        this.cancelDialog = false;
        this.preOrder = {};
        this.selectedOrder = {};
        this.loading = false;
        this.positionPriceType = '';
        this.strategy_list_trades = [];
        this.strategy_trades = {}
        this.strategy_positions = []
    }
}
const orders = new Orders();
export default orders;
