import moment from "moment";
import { getArray, getObject, isFalsy, isNull, isString, isTruthy } from "../../../helpers/Helper";
import { createOrder, createBulkOrder } from "../../../provider/Order";
import { getMarketByPId } from "../../../components/Common/OptionChain/Helper";
import { createVirtualOrder } from "../../../provider/VirtualOrder";
import Virtual_order from "../../../subscriber/VirtualOrder";
import option_chain from "../../../subscriber/OptionChain";
import orders from "../../../subscriber/Orders";
import broker from "../../../subscriber/Broker";
import config from "../../../subscriber/Config";
import users from "../../../subscriber/Users";

export const getOrderById = (orderId: string) => {
    if(!orderId) {
        return {}
    }
    const { ordersData } = orders;
    return getArray(ordersData).find((item: any) => item.order_id === orderId) || {};
}

export const getArrayByValue = (arr: any[], value: string) => {
    return getArray(arr).find((item: any) => item.value === value);
}

export const generateOrderId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000);
}

export const create_product_dec = (traded_product: any): string => {
    const { symbol, expiry, strike_price, option_type, instrument } = traded_product || {};
    const actionMap: { [key: string]: string } = {
        "FOP": symbol +" "+ moment(expiry).format("YYYYMMDD") +" " + strike_price  +" "+ (option_type === 'C' ? 'CE' : 'PE'),
        "FUT": symbol +" "+ moment(expiry).format("YYYYMMDD") +" "+ instrument
    }
    return actionMap[instrument] || '';
}

export const getLTP = (product_id: string): { ltp: string, putOrCall: string } => {
    let response = { ltp: "", putOrCall: "" };
    if (isFalsy(product_id)){
        return response
    }
    const { marketData } = option_chain;

    const marketEntry = getArray(marketData).find((entry: any) => Number(entry.conid) === Number(product_id));

    if (marketEntry) {
        response = {
            ltp: marketEntry.last_price,
            putOrCall: isString(marketEntry.option_type) && marketEntry.option_type === 'C' ? "CALL" : "PUT"
        };
    }

    return response;
};

export const getProductByProductId = (product_id: string): any => {
    const { marketData } = option_chain;

    for (const item of getArray(marketData)) {
        if (item?.conid === product_id) {
            return item
        }
    }
    return {};
}

export const priceCalulation = (marketData: Record<string, any>, side: string, priceType: string, isRound = false) => {
    // Safely extract values with default fallbacks
    const bidPrice = parseFloat(marketData.bid_price || "0.00");
    const askPrice = parseFloat(marketData.ask_price || "0.00");
    const ltp = parseFloat(marketData.last_price || "0.00");

    const isBuy = side === 'BUY';

    // Determine passive and aggressive prices
    const passivePrice = isBuy ? askPrice : bidPrice;
    const aggressivePrice = isBuy ? bidPrice : askPrice;

    // Calculate mid-price safely
    const midPriceValue = (bidPrice + askPrice) / 2;
    const midPrice = isRound
        ? (Math.round(midPriceValue * 2) / 2).toFixed(3)
        : ((midPriceValue * 2) / 2).toFixed(3);

    // Action map with string representations, ensuring valid defaults
    const actionMap: { [key: string]: string } = {
        "PASSIVE": passivePrice.toFixed(3),
        "AGGRESSIVE": aggressivePrice.toFixed(3),
        "MID": midPrice,
        "LTP": ltp.toFixed(3),
    };

    // Fetch price by type and convert to number, defaulting to 0.00
    const price = actionMap[priceType] || "0.00";
    return parseFloat(price);
}

export const calculatePrice = (side: string, priceType: string, product_id: string, isRound = false): number => {
    // Fetch market data, ensure it's an object or defaults to an empty object
    const marketData = getMarketByPId(Number(product_id)) || {};
    
    return priceCalulation(marketData, side, priceType, isRound);
};


export const calculatePriceWithMarketData = (marketDatas:any[], side: string, priceType: string, product_id: string, isRound = false): number => {
    const marketData = getArray(marketDatas).find(item => item?.conid === product_id)
    return priceCalulation(marketData, side, priceType, isRound);
}

export const calculateQuantity = (quantity: string, exitPosition: string = ''): number => {
    let quantityValue = Math.abs(parseInt(quantity));

    if (quantityValue === 0 || exitPosition === '') {
        return quantityValue;
    }
    
    return quantityValue * (parseInt(exitPosition) / 100);
};

export const array_update = (orders: any[], updatedArray: any[]) => {
    const updatedOrders = orders;
    
    getArray(updatedArray).forEach((updatedOrder) => {
        updatedOrder.selected = true
        // Find the index of the existing order with the same product_id
        const existingIndex = getArray(updatedOrders).findIndex(
            (order) => order.product_id === updatedOrder.product_id
        );
    
        if (existingIndex !== -1) {
            // If a matching order is found, update the quantity and price
            updatedOrders[existingIndex].quantity = updatedOrders[existingIndex].quantity + updatedOrder.quantity;
            updatedOrders[existingIndex].price = (updatedOrders[existingIndex].quantity + updatedOrder.price) / 2;
        } else {
            // If no matching order is found, add the updated order to the orders array
            updatedOrders.push(updatedOrder);
        }
    });

    return updatedOrders;
}

const validateOrders = (requestBody: any): boolean => {
    const isValid = !getArray(requestBody.orders).some(
        (item) => isNull(item.price) || isNull(item.quantity) || item.price === 0 || item.quantity === 0
    );
  
    if (!isValid) {
        config.updateValue(
            "apiError",
            "Order price or quantity is zero or null. Please check manually and resubmit the order."
        );
        return false;
    }
  
    return true;
};

// Define keys to remove
const keysToRemove = ["id", "token", "lot_size", "expiry", "instrument", "option_type", "tick_size", "option_open_interest", "put_call_interest", "type", "selected"];
  
export const orderSubmit = async (requestInfo: any, preOrder: any): Promise<any>  => {
    const order_ref = moment().format("YYYYMMDDHHmmssSSS")
    const { id, selected_strateg } = users;
    const { currentBroker, brokerInfo } = broker;
    const { accountID = '', id: broker_id } = getObject(brokerInfo)[currentBroker] || {};
    const { expiry_date, conid, sectype, strike_price, putOrCall, trading_symbol } = preOrder;
    const { draftVirtualOrder, isVirtualOrder = false, isInlineOrder = false, updateValue, updateMultiValue } = Virtual_order

    // Create a filtered requestInfo object
    const filteredRequestInfo = Object.fromEntries(
        Object.entries(requestInfo).filter(([key]) => !keysToRemove.includes(key))
    );

    const requestBody = {
        "broker": currentBroker,
        "orders": [
            {
                "acctId": accountID,
                "conid": conid,
                "secType": sectype,
                "outsideRTH": false,
                "user_id": id?.toString(),
                "broker_id": broker_id,
                "strategy_name": selected_strateg?.value,
                "product_id": conid,
                "trading_symbol": trading_symbol,
                "strike": strike_price,
                "status": isTruthy(isVirtualOrder) ? "VERTUAL" : "OPEN",
                "order_id": generateOrderId(),
                "expiry_date": moment(expiry_date).format('YYYY-MM-DD'),
                "putOrCall": putOrCall,
                "order_ref": order_ref,
                "cOID":order_ref,
                "account_id": accountID,
                ...filteredRequestInfo
            }
        ]
    }
    const isValid = validateOrders(requestBody);
    if(isFalsy(isValid)) {
        return false
    }
    let status = false;
    if(isTruthy(isInlineOrder)) {
        status = true;
        orders.updateValue("orderDialog", false);
        updateMultiValue({
            "isInlineOrder": false,
            "isVirtualOrder": false,
            "draftVirtualOrder": array_update(draftVirtualOrder, requestBody?.orders) || []
        })
    }else if(isTruthy(isVirtualOrder)) {
        status = await createVirtualOrder(requestBody);
        updateValue("isVirtualOrder", false);
    }else {
        status = await createOrder(requestBody);
    }
    return status
}

export const bulkOrderSubmit = async (requestInfo: any[]): Promise<any> => {
    const { id, selected_strateg } = users;
    const { currentBroker, brokerInfo } = broker;
    const { accountID = '', id: broker_id } = getObject(brokerInfo)[currentBroker] || {};
    const orders = getArray(requestInfo).map((item, key) => {
        // Create a filtered requestInfo object
        const filteredRequestInfo = Object.fromEntries(
            Object.entries(item).filter(([key]) => !keysToRemove.includes(key))
        );

        const orderRef = moment().format("YYYYMMDDHHmmssSSS");
        return {
            "acctId": accountID,
            "outsideRTH": false,
            "user_id": id?.toString(),
            "broker_id": broker_id,
            "strategy_name": selected_strateg?.value,
            "status": "OPEN",
            "order_id": generateOrderId(),
            "order_ref": orderRef+key,
            "cOID": orderRef+key,
            "account_id": accountID,
            ...filteredRequestInfo
        };
    });
    const requestBody = {
        "broker": currentBroker,
        "orders": orders
    };
    const status = await createBulkOrder(requestBody);
    return status
}

export const createDraftOrder = async (requestInfo: any): Promise<any>  => {
    const { id, selected_strateg } = users;
    const { currentBroker, brokerInfo } = broker;
    const { accountID = '', id: broker_id } = getObject(brokerInfo)[currentBroker] || {};
    const { expiry_date, conid, sectype, strike_price, putOrCall, trading_symbol, strategy_name } = requestInfo;
    const { draftVirtualOrder, isVirtualOrder = false, updateMultiValue } = Virtual_order
    const order_ref = moment().format("YYYYMMDDHHmmss") + conid

    // Create a filtered requestInfo object
    const filteredRequestInfo = Object.fromEntries(
        Object.entries(requestInfo).filter(([key]) => !keysToRemove.includes(key))
    );

    const requestBody = {
        "broker": currentBroker,
        "orders": [
            {
                "acctId": accountID,
                "conid": conid,
                "secType": sectype,
                "outsideRTH": false,
                "user_id": id?.toString(),
                "broker_id": broker_id,
                "strategy_name": strategy_name !== '' ? strategy_name : selected_strateg?.value,
                "product_id": conid,
                "trading_symbol": trading_symbol,
                "strike": strike_price,
                "status": isTruthy(isVirtualOrder) ? "VERTUAL" : "OPEN",
                "order_id": generateOrderId(),
                "expiry_date": moment(expiry_date).format('YYYY-MM-DD'),
                "putOrCall": putOrCall,
                "order_ref": order_ref,
                "cOID":order_ref,
                "account_id": accountID,
                ...filteredRequestInfo
            }
        ]
    }
    const isValid = validateOrders(requestBody);
    if(isFalsy(isValid)) {
        return false
    }
    
    const status = true;
    updateMultiValue({
        "isInlineOrder": false,
        "isVirtualOrder": false,
        "draftVirtualOrder": array_update(draftVirtualOrder, requestBody?.orders) || []
    });
    return status
}