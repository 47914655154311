import React, { useState, ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { FormHelperText, styled } from '@mui/material';

interface CustomInputProps {
    label?: string;
    value?: string | number | null;
    placeholder?: string;
    type?: 'text' | 'password' | 'email' | 'number';
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    isToggleable?: boolean;
    errorMessage?: string;
    onChange?: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    inputRef?: React.Ref<HTMLInputElement>;
    extraProps?: any;
    style?: React.CSSProperties;
    fontSize?: number | string;
    inputStyle?: React.CSSProperties;
    hidden?: boolean
}
interface StyledInputLabelProps extends CustomInputProps {
    fontSize?: number | string;
}
const FullWidthFormControl = styled(FormControl)`
  width: 100%;
`;
const StyledInputLabel = styled(InputLabel)<StyledInputLabelProps>(({ theme, fontSize }) => ({
    '&.MuiInputLabel-root': {
      fontSize: fontSize,
      top: '-7px'
    },
    '&.Mui-focused': {
      top: fontSize ? '3px !important' : '0'
    },
    '&.MuiFormLabel-filled': {
      top: fontSize ? '3px !important' : '0'
    },
    '&.MuiInputLabel-shrink': {
      top: fontSize ? '-5px' : '0'
    }
}));

export const CustomInput: React.FC<CustomInputProps> = ({
    label,
    value,
    placeholder,
    type = 'text',
    leftIcon,
    rightIcon,
    isToggleable = false,
    errorMessage,
    onChange,
    onFocus,
    onBlur,
    inputRef,
    extraProps,
    style,
    fontSize,
    inputStyle,
    hidden
}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e.target.value);
        }
    };

    return (
        <FullWidthFormControl>
            <FormControl sx={{ m: 1, ...style, display: hidden === true ? 'none': 'block' }} variant="outlined" error={!!errorMessage}>
                {label && <StyledInputLabel fontSize={fontSize} htmlFor="custom-input">
                    {label}
                </StyledInputLabel>}
                <OutlinedInput
                    id="custom-input"
                    type={isToggleable && type === 'password' ? (isPasswordVisible ? 'text' : 'password') : type}
                    value={value}
                    onChange={handleInputChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                            fontSize: fontSize,
                            ...inputStyle
                        }
                    }}
                    endAdornment={
                        isToggleable && type === 'password' ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={togglePasswordVisibility}
                                    edge="end"
                                >
                                    <i className={`bi ${isPasswordVisible ? 'bi-eye-slash' : 'bi-eye'}`} />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }
                    label={label}
                    placeholder={placeholder}
                    inputRef={inputRef}
                    {...extraProps}
                    startAdornment={leftIcon ? <InputAdornment position="start">{leftIcon}</InputAdornment> : null}
                />
                {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        </FullWidthFormControl>
    );
};