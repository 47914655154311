
export const baseUrl: string = process.env.REACT_APP_API_BASE_URL + "/v1/" || "";

export const apiUrls: Record<string, any> = {
    init: 'init',
    auth: 'auth',
    register: 'register',
    logout: 'logout',
    // Thirdparty
    ibkrAuth: 'ibkr_auth',
    ibkrLogout: 'ibkr_logout',
    ibkrTickle: 'ibkr_tickle',
    ibkrBrokerOrders: 'ibkr_broker_orders',
    TPGetUserOrder: 'tp_get_user_order',
    TPGetTrades: 'tp_get_user_trades',
    TPGetPosition: 'tp_get_user_positions',
    TpTriggerMarket: 'tp_market_data',
    TpRequestMarket: 'tp_request_market_data',
    TpRemoveMarket: 'tp_remove_market',
    TpStopMarket: 'tp_stop_market',
    TPCreateOrder: 'tp_create_order',
    TPReplayOrder: 'tp_order_replay',
    TPCancelOrder: 'tp_cancel_order',
    TPUpdateOrder: 'tp_update_order',
    TpGetProducts: 'tp_get_products',
    tpTriggerOS: 'tp_order_status',
    tpRequestOS: 'tp_request_order_status',
    tpStopOS: 'tp_stop_order_status',
    TPCreateBulkOrder: 'tp_bulk_create_order',
    // limits
    userLimit: 'user_limit',
    // strategies
    GetStrategy: 'get_user_strategies',
    CreateStrategy: 'add_user_strategies',
    GetStrategyTrde: 'get_strategy_trades',
    GetUpdateStrategyTrde: 'get_latest_strategy_trades',
    DeleteStrategyTrde: 'delete_strategy_trade',
    UpdateStrategyTrades: 'update_strategy_trade_ids',
    UpdateVertualOrder: 'update_vertual_order_ids',
    // virtual_orders
    AddVirtualOrders: 'add_virtual_orders',
    ExecuteVirtualOrders: 'execute_virtual_orders',
    DeleteVirtualOrder: 'delete_virtual_order',
    // stop loss
    GetStopLoss: "get_stop_loss",
    GetLatestStopLoss: "get_latest_stop_loss",
    UpdateStopLoss: "update_stop_loss",
    ResetStopLoss: "reset_stop_loss",
    getStopLossList: "get_stop_loss_positions",
    InsertStopLoss: "insert_stop_loss_order",
    DeleteStopLoss: "delete_stop_loss_order",
    // Target Profit
    getTargetProfitList: "get_target_profit_positions",
    InsertTargetProfit: "insert_target_profit",
    DeleteTargetProfit: "delete_target_profit",
    // Users
    users: "users",
    getUsers: "user_management",
}

export const ibkrURLs: Record<string, any> = {
    auth: 'iserver/auth/status',
    market: 'iserver/marketdata/snapshot'
}