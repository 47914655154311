import React, { FC, ReactNode } from "react";
import { Button } from '@mui/material';
import { isTruthy } from "../../../helpers/Helper";

interface ButtonProps {
    colorCode?: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning";
    variant?: 'contained' | 'text' | 'outlined';
    size?: 'small' | 'medium' | 'large';
    children: ReactNode;
    onClick: () => void;
    isAvailable?: boolean;
    customStyle?: React.CSSProperties;
}

export const MuiButtons: FC<ButtonProps> = ({ colorCode = "primary", variant = "contained", size = "small", children, onClick, isAvailable, customStyle = {} }) => {
    let buttonStyle = {};

    return (
        <Button
            color={colorCode}
            variant={variant}
            style={{...buttonStyle, ...customStyle}}
            onClick={isAvailable ? undefined : onClick}
            disabled={isTruthy(isAvailable)}
            size={size}
        >
            {children}
        </Button>
    );
}
