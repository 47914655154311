import { callApi } from "../helpers/Api";
import { baseUrl } from "../helpers/Config";
import { getArray, getArrayLength, getObject, getObjectLength, isArrayTruthy, isObjectTruthy } from "../helpers/Helper";
import { createStrategyPositions, formedStrategyTrade, selecte_all_position } from "../screens/Analyze/helpers/Helpers";
import { check_position_become_zero, create_stop_loss_setting } from "../screens/Analyze/helpers/StopLossHelper";
import Analyze from "../subscriber/Analyze";
import config from "../subscriber/Config";
import orders from "../subscriber/Orders";
import targetProfits, { settingValue } from "../subscriber/TargetProfits";
import users from "../subscriber/Users";

export const getStrategies = () => {
    const functionUrl = `GetStrategy`;
    const requestBody = {};
    const method = 'GET';
    const isBackground = true
    const { id, role_id, parent_id } = users
    const urlValue = (role_id === 1) ? id : parent_id;
    callApi({baseUrl, functionUrl, requestBody, method, urlValue, isBackground})
    .then((response: any) => {
        const { status = false, result = [] } = response || {}
        if (isArrayTruthy(result) && status && result.length > 0) {
            const updatedRes = formedStragies(result);
            createProfitStrategyTemplate(result);
            users.updateMultiValue({
                'strategy_list': result,
                'strategies': updatedRes,
                'selected_strateg': getObject(updatedRes[0])
            });
            return true;
        }
        users.updateMultiValue({
            'strategies': []
        });
        return false
    }).catch(error => {
        console.error("API error getStrategies: ", error);
    });
}

export const createStrategy = (requestBody: any): any => {
    const functionUrl = `CreateStrategy`;
    const method = 'POST';
    return callApi({baseUrl, functionUrl, requestBody, method})
    .then((response: any) => {
        const { status = false, result } = response || {}
        if (status) {
            addNewStrategyTP(result)
            return {status: true, error: result?.message}
        }
        return {status: false , error: result?.message}
    }).catch(error => {
        console.error("API error getStrategies: ", error);
    });
}

export const getStrategyTrade = () => {
    const functionUrl = `GetStrategyTrde`;
    const requestBody = {};
    const method = 'GET';
    const urlValue = users.id;
    const isBackground = true
    callApi({baseUrl, functionUrl, requestBody, method, urlValue, isBackground})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        if (isArrayTruthy(result) && status && result.length > 0) {
            const updatedRes = formedStrategyTrade(result);
            const { selected_strateg } = users;
            const { selected_strategy } = Analyze;
            const strategy_id = getObjectLength(selected_strategy) > 0 ? selected_strategy.value : selected_strateg?.value;
            const strategyPositions = createStrategyPositions(updatedRes, strategy_id);
            orders.updateMultiValue({
                "strategy_list_trades": result,
                "strategy_trades": updatedRes,
                "strategy_positions": strategyPositions
            });
        }else {
            orders.updateMultiValue({
                "strategy_list_trades": [],
                "strategy_trades": {},
                "strategy_positions": []
            });
        }
    }).catch(error => {
        console.error("API error getStrategies: ", error);
    });
}

export const getUpdateStrategyTrade = () => {
    const functionUrl = `GetUpdateStrategyTrde`;
    const requestBody = {};
    const method = 'GET';
    const urlValue = users.id;
    const isBackground = true
    callApi({baseUrl, functionUrl, requestBody, method, urlValue, isBackground})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        if (isObjectTruthy(result) && status && getObjectLength(result) > 0) {
            const trades = getArray(result.trades);
            const missingTradeIds = getArray(result.missing_trade_ids);
            
            if (!getArrayLength(missingTradeIds) && !getArrayLength(trades)) {
                return;
            }

            let already_list = orders.strategy_list_trades;
            if(getArrayLength(missingTradeIds) > 0) {
                already_list = getArray(already_list).filter(item => !missingTradeIds.includes(item.trade_id))
            }

            if(getArrayLength(trades) > 0) {
                already_list.push(...trades)
            }
            const updatedRes = formedStrategyTrade(already_list);
            const { selected_strateg } = users
            const { selected_strategy } = Analyze
            const strategy_id = getObjectLength(selected_strategy) > 0 ? selected_strategy.value : selected_strateg?.value;
            const strategyPositions = createStrategyPositions(updatedRes, strategy_id)
            const positions = selecte_all_position(strategyPositions)
            // creating mobx row for new trades
            create_stop_loss_setting(already_list);
            check_position_become_zero(positions);
            
            orders.updateMultiValue({
                "strategy_list_trades": already_list,
                "strategy_trades": updatedRes,
                "strategy_positions": strategyPositions
            });
        }
    }).catch(error => {
        console.error("API error getStrategies: ", error);
    });
}

export const deleteStrategyTrade = (trade_id: string) => {
    const functionUrl = `DeleteStrategyTrde`;
    const requestBody = {};
    const method = 'DELETE';
    const urlValue = trade_id;
    return callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = "" } = response || {}
        if (status) {
            config.updateValue("apiSuccess", result);
            const { selected_strategy } = Analyze;
            const { strategy_list_trades } = orders;
            const trades: any = getArray(strategy_list_trades).filter(
                (item) => item.trade_id !== trade_id
            );
            
            const updatedRes = formedStrategyTrade(trades);
            const strategyPositions = createStrategyPositions(updatedRes, selected_strategy?.value);
            orders.updateMultiValue({
                "strategy_list_trades": trades,
                "strategy_trades": updatedRes,
                "strategy_positions": strategyPositions
            });
            return true
        }
        return false
    }).catch(error => {
        console.error("API error getStrategies: ", error);
    });
}

const formedStragies = (result: any[]): any[] => {
    const response: any[] = [];
    getArray(result).forEach(item => {
        const options = {
            "label": item.strategy_name,
            "value": item.id,
        }
        response.push(options)
    });
    return response
}

const createProfitStrategyTemplate = (result: any[]): void => {
    const response: settingValue[] = targetProfits.settings || [];
    if(getArrayLength(response) === 0) {
        getArray(result).forEach(item => {
            const template = tpTemplate(item)
            response.push(template)
        });
    }
    targetProfits.updateValue('settings', response)
}

const addNewStrategyTP = (result: any): void => {
    const { settings, updateValue } = targetProfits;
    const new_settings = tpTemplate(result);
    updateValue("settings", [...settings, new_settings]);
}

const tpTemplate = (item: any): settingValue => {
    return {
        strategy_name: item.id,
        profit_setting: null,
        loss_setting: null,
        status: false,
        is_order_placed: false
    }
}