import React from 'react';
import Select, { ActionMeta, MultiValue, components } from 'react-select';
import { getArray } from '../../../helpers/Helper';

interface Option {
    value: string;
    label: string;
}

interface CustomSelectProps {
    options: Option[];
    value?: Option | null;
    onChange?: (selectedOption: Option | null) => void;
    placeholder?: string | null;
    fontSize?: string;
}

// Adjusted interface for multi-select props
interface CustomMultiSelectProps {
    options: Option[];
    value?: Option[] | null; // Handle an array of selected values
    onChange?: (selectedOptions: MultiValue<Option>) => void; // Handle array of options
    placeholder?: string | null;
}  

export const CustomSelect: React.FC<CustomSelectProps> = ({ options, value, onChange, placeholder, fontSize }) => {
    const customStyles = {
        control: (base: any) => ({
            ...base,
            minHeight: fontSize ? '40px' : '56px',
        }),
        valueContainer: (base: any) => ({
            ...base,
            padding: '2px 8px',
        }),
        input: (base: any) => ({
            ...base,
            fontSize: fontSize ? fontSize : '16px',
        }),
        option: (base: any, state: { isFocused: any; isSelected: boolean }) => ({
            ...base,
            backgroundColor: state.isFocused ? 'lightgray' : 'white',
            color: state.isSelected ? '#1976d2' : 'black',
            fontSize: fontSize ? fontSize : '16px'
        }),
        singleValue: (base: any) => ({
            ...base,
            fontSize: fontSize ? fontSize : '16px',
            color: 'black',
            backgroundColor: 'white'
        }),
        multiValue: (base: any) => ({
            ...base,
            fontSize: fontSize ? fontSize : '16px',
            color: 'black',
            backgroundColor: 'white'
        }),
        placeholder: (base: any) => ({
            ...base,
            fontSize: fontSize ? fontSize : '16px',
        }),
        menu: (base: any) => ({
            ...base,
            zIndex: 1050, // Ensure this is higher than the z-index of your table header
        }),
        menuList: (base: any) => ({
            ...base,
            zIndex: 1050, // Ensure this is higher than the z-index of your table header
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
    };

    const handleChange = (selectedOption: Option | null) => {
        if (onChange) {
            onChange(selectedOption);
        }
    };

    return (
        <Select
            options={options}
            value={value}
            onChange={handleChange}
            isSearchable
            placeholder={placeholder}
            styles={customStyles}
            menuPortalTarget={document.body} // Render menu in a portal
            menuPlacement="auto"
            menuPosition="fixed"
        />
    );
};

export const CustomMultiSelect: React.FC<CustomMultiSelectProps> = ({ options, value, onChange, placeholder }) => {

    const customStyles = {
        control: (base: any) => ({
          ...base,
          // You can control the height of the menu with `minHeight`.
          minHeight: '40px',
        }),
        valueContainer: (base: any) => ({
          ...base,
          // Adjust the padding inside the input
          padding: '2px 8px',
        }),
        input: (base: any) => ({
          ...base,
          // Inline styles for the input
          fontSize: '16px',  // Example font size
        }),
        option: (base: any, state: { isFocused: any; }) => ({
          ...base,
          // Different styles for different states (normal, hover, active)
          backgroundColor: state.isFocused ? 'lightgray' : 'white',
          fontSize: '16px'  // Example font size
        }),
        singleValue: (base: any) => ({
          ...base,
          fontSize: '16px',  // Example font size for the selected item
          color: 'black', // Ensure text color is visible
          backgroundColor: 'white' // Ensure background color is appropriate
        }),
        multiValue: (base: any) => ({
          ...base,
          fontSize: '16px',  // Example font size for the selected items
          color: 'black', // Ensure text color is visible
          backgroundColor: 'white' // Ensure background color is appropriate
        }),
        placeholder: (base: any) => ({
          ...base,
          fontSize: '16px', // Example font size for the placeholder
        }),
    };

    const handleChange = (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
        if (onChange) {
        onChange(newValue);
        }
    };

    const getValue = () => {
        return options.filter((option) => getArray(value).includes(option.value));
    }

    return (
        <Select
            isMulti
            components={{ MultiValue: components.MultiValue }}
            options={options}
            value={getValue() || []}
            onChange={handleChange}
            isSearchable
            placeholder={placeholder}
            styles={customStyles}
        />
    );
};
