import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useCustomNavigation } from '../utils/utilities';

const NotFound: React.FC = () => {
    const navigate = useCustomNavigation();

    return (
        <Container maxWidth="sm">
            <Box textAlign="center" marginTop={10}>
                <Typography variant="h2" gutterBottom>
                    404 - Page Not Found
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The page you are looking for does not exist.
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => navigate(-1)} 
                    sx={{ margin: 1 }}
                >
                    Go Back
                </Button>
                <Button 
                    variant="outlined" 
                    color="secondary" 
                    onClick={() => navigate('/login')} 
                    sx={{ margin: 1 }}
                >
                    Go to Login Page
                </Button>
            </Box>
        </Container>
    );
};

export default NotFound;
