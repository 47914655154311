import React from 'react';
import { observer } from "mobx-react";
import { AlertDialog } from './Alert/AlertDialog';
import { useHandleErrorByCode } from '../../helpers/Helper';
import { useCustomNavigation } from '../../utils/utilities';
import broker from '../../subscriber/Broker';
import config from '../../subscriber/Config';

const ErrorPage:React.FC = observer(() => {
    const navigation = useCustomNavigation()

    const { apiError, apiErrorCode, updateMultiValue } = config;
    const { currentBroker } = broker

    const ErrorHandeling = () => {
        useHandleErrorByCode(apiErrorCode);
        updateMultiValue({
            apiError: '',
            apiErrorCode: ''
        });
        navigation('/brokers')
    }

    return (
        <AlertDialog
            visible={currentBroker && apiErrorCode ? true : false}
            message={apiError}
            handleClose={ErrorHandeling}
            onSubmitCallback={ErrorHandeling}
            onCancelCallback={ErrorHandeling} />
    );
});

export default ErrorPage;
