import { makeAutoObservable } from 'mobx';
import { isObjectTruthy } from '../helpers/Helper';
import { makePersistable } from 'mobx-persist-store';

interface IValues {
    [key: string]: any;
}

class Users {
    id: number | null = null;
    role_id : number | null = null;
    parent_id: number | null = null;
    account_id: string = ''
    first_name: string = '';
    last_name: string = '';
    email: string = '';
    phone_number: number | null = null;
    status: string = '';
    subscription_id: number | null = null;
    expiry_date: number | null = null;
    loading: boolean = false;
    strategy_list: any[] = []
    strategies: any[] = [];
    selected_strateg: any = {}

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'UsersStore',
            properties: ['id', 'role_id', 'parent_id', 'account_id', 'first_name', 'last_name', 'email', 'phone_number', 'status', 'subscription_id', 'expiry_date', 'strategy_list', 'strategies'],
            storage: localStorage
        });
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        if(isObjectTruthy(values)) {
            Object.assign(this, values);
        }
    }

    reset = () => {
        this.id = null;
        this.role_id = null;
        this.parent_id = null;
        this.account_id = '';
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phone_number = null;
        this.status = '';
        this.subscription_id = null;
        this.expiry_date = null;
        this.loading = false;
        this.strategy_list = [];
        this.strategies = [];
        this.selected_strateg = {};
    }
}
const users = new Users();
export default users;
