import { makeAutoObservable } from 'mobx';
import { getObject } from '../helpers/Helper';

interface IValues {
    [key: string]: any;
}

class DeltaStrategies {
    expiryDate: any = {};
    deltaStrategyData: any[] = [];
    loading: boolean = false;
    products: any[] = [];
    future: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        Object.keys(getObject(values)).forEach((key) => {
            (this as any)[key] = values[key];
        });
    }

    reset = () => {
        this.expiryDate = {};
        this.deltaStrategyData = [];
        this.loading = false;
        this.products = [];
        this.future = [];
    }
}
const deltaStrategies = new DeltaStrategies();
export default deltaStrategies;
