import React, { Suspense, useEffect } from 'react';
import { init } from './provider/Auth';
import { Navigation } from './components/Navigation';
import config from './subscriber/Config';
import RootWapper from './RootWapper';

const App : React.FC = () => {
    const { device_encryption_key } = config;
    useEffect(() => {
        init();
        if(!device_encryption_key) {
        }
    }, [device_encryption_key]);

    return (
        <div className="App">
            <RootWapper />
            <Suspense fallback={<div className='d-flex justify-content-center align-items-center'>{"Loading..."}</div>}>
                <Navigation />
            </Suspense>
        </div>
    );
}

export default App;
