import { makeAutoObservable } from 'mobx';
import { getObject } from '../helpers/Helper';

interface IValues {
    [key: string]: any;
}

class OpenInterest {
    expiryDate: Date | null = new Date();
    openInterestData: any[] = []
    products: any[] = [];
    future: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        Object.keys(getObject(values)).forEach((key) => {
            (this as any)[key] = values[key];
        });
    }

    reset = () => {
        this.expiryDate = new Date();
        this.openInterestData = [];
        this.products = [];
        this.future = [];
    }
}
const openInterest = new OpenInterest();
export default openInterest;
