import { getArray, getObjectLength } from "../../../helpers/Helper"
import orders from "../../../subscriber/Orders"

export const calculate_mtm_and_pnl = (position: any, type: string, price: number, product_info: any = {}) => {
    const { avg_quantity, avg_price, day_sell_qut, day_buy_qut, cf_buy_qut, cf_sell_qut, open_price, realised_pnl, traded_product } = position
    let multiplier = 0
    if(getObjectLength(traded_product)) {
        multiplier = traded_product.lot_size;
    }else if(getObjectLength(traded_product)) {
        multiplier = product_info.lot_size;
    }

    let cf_mtm = 0.0
    let day_mtm = 0.0
    if (Number(cf_sell_qut) > 0 || day_sell_qut> 0) {
        cf_mtm = (cf_sell_qut * open_price) - (cf_sell_qut * price)
        day_mtm = (day_sell_qut * avg_price) - (day_sell_qut * price)
    }else if (cf_buy_qut > 0 || day_buy_qut > 0) {
        cf_mtm = (cf_buy_qut * price) - (cf_buy_qut * open_price)
        day_mtm = (day_buy_qut * price) - (day_buy_qut * avg_price)
    }
    let cf_pnl = 0.0
    let day_pnl = 0.0
    if (Number(cf_sell_qut) > 0 || Number(day_sell_qut) > 0) {
        cf_pnl = (cf_sell_qut * avg_price) - (cf_sell_qut * price)
        day_pnl = (day_sell_qut * avg_price) - (day_sell_qut * price)
    }else if (Number(cf_buy_qut) > 0 || day_buy_qut > 0) {
        cf_pnl = (cf_buy_qut * price) - (cf_buy_qut * avg_price)
        day_pnl = (day_buy_qut * price) - (day_buy_qut * avg_price)
    }

    const realised_pnl_value = Number(realised_pnl)
    let mtm = (cf_mtm + day_mtm) * multiplier
    let pnl = (cf_pnl + day_pnl) * multiplier
    if (Number(avg_quantity) === 0) {
        mtm = 0
        pnl = 0
    }
    return {"mtm": mtm.toFixed(3), "pnl": pnl.toFixed(3), "realised_pnl": realised_pnl_value.toFixed(3)}
}

export const calculate_realised_pnl = (trades: any[]): string => {
    let realised_pnl = 0, net_qty = 0, buy_qty = 0, sell_qty = 0, buy_total = 0, sell_total = 0, total_commission = 0, multiplier = 0;

    trades.forEach(trade => {
        const { side, quantity, price, traded_product, commission = 0 } = trade;
        const { lot_size } = traded_product;
        multiplier = lot_size;
        total_commission += Number(commission);
        const qty = Number(quantity);
        const prc = Number(price);

        if (side === 'BUY') {
            net_qty += qty;
            buy_qty += qty;
            buy_total += prc * qty;
        } else if (side === 'SELL') {
            net_qty -= qty;
            sell_qty += qty;
            sell_total += prc * qty;
        }
    });

    let sum_pnl = 0;
    const buy_avg = buy_qty > 0 ? buy_total / buy_qty : 0;
    const sell_avg = sell_qty > 0 ? sell_total / sell_qty : 0;

    if (net_qty === 0) {
        sum_pnl = (sell_qty * sell_avg) - (sell_qty * buy_avg);
    } else if (net_qty < 0) {
        sum_pnl = ((sell_qty + net_qty) * sell_avg) - ((sell_qty + net_qty) * buy_avg);
    } else if (net_qty > 0) {
        sum_pnl = (sell_qty * sell_avg) - (sell_qty * buy_avg);
    }

    realised_pnl = (sum_pnl * multiplier) - total_commission;
    return realised_pnl.toFixed(3);
}

export const calculate_un_realised_pnl = (trades: any[], currentPrice: number): string => {
    let unrealised_pnl = 0, net_qty = 0, buy_qty = 0, sell_qty = 0, buy_total = 0, sell_total = 0, multiplier = 0;

    trades.forEach(trade => {
        const { side, quantity, price, traded_product } = trade;
        const { lot_size } = traded_product;
        multiplier = lot_size;
        const qty = Number(quantity);
        const prc = Number(price);

        if (side === 'BUY') {
            net_qty += qty;
            buy_qty += qty;
            buy_total += prc * qty;
        } else if (side === 'SELL') {
            net_qty -= qty;
            sell_qty += qty;
            sell_total += prc * qty;
        }
    });

    let sum_pnl = 0;
    const buy_avg = buy_qty > 0 ? buy_total / buy_qty : 0;
    const sell_avg = sell_qty > 0 ? sell_total / sell_qty : 0;

    if (net_qty === 0) {
        sum_pnl = 0;
    } else if (net_qty < 0) {
        sum_pnl = (sell_avg - Number(currentPrice));
    } else if (net_qty > 0) {
        sum_pnl = (Number(currentPrice) - buy_avg);
    }

    unrealised_pnl = (sum_pnl * Math.abs(net_qty)) * multiplier;
    return unrealised_pnl.toFixed(3);
}

export const position_by_product_id = (product_id: string) => {
    const { positions } = orders;
    return getArray(positions).find(item => item.product_id === product_id) ?? {}
}

export const headers = [
    { id: 'id', label: 'Id', numeric: false, hidden: true },
    { id: 'user_id', label: 'User Id', numeric: false, hidden: true },
    { id: 'action', label: 'Action', visible: true },
    { id: 'product_dec', label: 'Product', numeric: false, visible: true },
    { id: 'strategy_name', label: 'Strategie Name', numeric: false, visible: false },
    { id: 'mtm', label: 'MTM', numeric: true, price: true, visible: true },
    { id: 'realised_pnl', label: 'Re PnL', numeric: true, price: true, visible: true },
    { id: 'pnl', label: 'PnL', numeric: true, price: true, visible: true },
    { id: 'avg_quantity', label: 'Net Qty', numeric: true, visible: true },
    { id: 'avg_price', label: 'avg price', numeric: true, price: true, visible: true },
    { id: 'price', label: 'Price', numeric: true, price: true, visible: true },
    { id: 'commission', label: 'Commission', numeric: true, visible: true },
    { id: 'day_buy_qut', label: 'Day Buy Qut', numeric: true },
    { id: 'day_sell_qut', label: 'Day Sell Qut', numeric: true },
    { id: 'day_buy_avg_prc', label: 'Day Buy avg prc', numeric: true, price: true },
    { id: 'day_sell_avg_prc', label: 'Day Sell avg prc', numeric: true, price: true },
    { id: 'day_buy_amount', label: 'Day Buy Amount', numeric: true },
    { id: 'day_sell_amount', label: 'Day Sell Amount', numeric: true },
    { id: 'cf_buy_qut', label: 'CF Buy Qut', numeric: true },
    { id: 'cf_sell_qut', label: 'CF Sell Qut', numeric: true },
    { id: 'cf_buy_avg_prc', label: 'CF Buy avg prc', numeric: true },
    { id: 'cf_sell_avg_prc', label: 'CF Sell avg prc', numeric: true },
    { id: 'cf_buy_amount', label: 'CF Buy Amount', numeric: true },
    { id: 'cf_sell_amount', label: 'CF Sell Amount', numeric: true },
    { id: 'open_price', label: 'Open Price', numeric: true, visible: true },
    { id: 'close_price', label: 'Close Price', numeric: true, visible: true },
];