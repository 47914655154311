import React, { useState, useEffect } from 'react';
import config from '../../../subscriber/Config';
import { observer } from 'mobx-react';

interface CustomAlertProps {
    type: string;
    message: string;
}

export const CustomAlert: React.FC<CustomAlertProps> = observer(({ type, message }) => {
    const [show, setShow] = useState(true);
    
    const handleClose = () => {
        setShow(false);
        config.updateMultiValue({
            "apiError": "",
            "apiSuccess": ""
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleClose();
        }, 5000);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
        <div
            className={`alert alert-${type} ${show ? 'visible' : 'invisible'}`}
            role="alert"
            style={{ position: 'fixed', top: '10px', right: '10px', width: '25%', zIndex: 1000 }}
        >
            {message}
        </div>
    );
});