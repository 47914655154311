import { callApi } from "../helpers/Api";
import { baseUrl } from "../helpers/Config";
import { isArrayTruthy, isObjectTruthy } from "../helpers/Helper";
import { check_stop_loss_response } from "../screens/Analyze/helpers/StopLossHelper";
import config from "../subscriber/Config";
import stopLoss from "../subscriber/StopLoss";
import users from "../subscriber/Users";

export const getStopLoss = () => {
    const functionUrl = `GetStopLoss`;
    const requestBody = {};
    const method = 'GET';
    const urlValue = `${users.id}`;
    const isBackground = true

    return callApi({baseUrl, functionUrl, requestBody, method, urlValue, isBackground})
    .then((response: any) => {
        const { status = false, result = {} } = response || {}
        if (isArrayTruthy(result) && status && result.length > 0) {
            stopLoss.updateValue("settings", result)
            return true;
        }
        return false;
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const updateStopLoss = (requestBody: any, id: any) => {
    const functionUrl = `UpdateStopLoss`;
    const method = 'POST';
    const urlValue = `${id}`;

    return callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = "" } = response || {}
        if (status) {
            config.updateValue("apiSuccess", result)
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const resetStopLoss = (id: any) => {
    const functionUrl = `ResetStopLoss`;
    const method = 'DELETE';
    const urlValue = `${id}`;

    callApi({baseUrl, functionUrl, method, urlValue})
    .then((response: any) => {
        const { status = false, result = "" } = response || {}
        if (status) {
            config.updateValue("apiSuccess", result)
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const getStopLossList = (requestBody: any) => {
    const functionUrl = `getStopLossList`;
    const method = 'POST';
    const urlValue = users.id

    callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = {} } = response || {};
        if (isObjectTruthy(result) && status) {
            const responses = check_stop_loss_response(requestBody, result);
            stopLoss.updateValue("positions", responses);
        }
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const insertStopLoss = (requestBody: any, id: any) => {
    const functionUrl = `InsertStopLoss`;
    const method = 'POST';
    const urlValue = `${id}`;

    return callApi({baseUrl, functionUrl, requestBody, method, urlValue})
    .then((response: any) => {
        const { status = false, result = "" } = response || {}
        if (status) {
            config.updateValue("apiSuccess", result)
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}

export const deleteStopLoss = (id: any) => {
    const functionUrl = `DeleteStopLoss`;
    const method = 'DELETE';
    const urlValue = `${id}/${users.id}`;

    callApi({baseUrl, functionUrl, method, urlValue})
    .then((response: any) => {
        const { status = false, result = "" } = response || {}
        if (status) {
            config.updateValue("apiSuccess", result)
            return true;
        }
        return false
    }).catch(error => {
        console.error("API error: ", error);
    });
}