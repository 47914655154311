export const Brokers = {
    IBKR: 'IBKR',
    FIRSTSTOCK: 'FIK'
}
export const TradeStatus = {
    OPEN: "open",
    ACTIVE: "active",
    COMPLETE: "completed",
    REJECT: "reject"
}

export const getStrategy = (strategy: string) => {
    const actionMap: { [key: string]: string } = {
        "BULLCALLSPREAD": 'bull_call_spread',
        "BULLPUTSPREAD": 'bull_put_spread',
        "BULLLONGCALENDAR": 'bull_long_calendar',
        "BEARCALLSPREAD": 'bear_call_spread',
        "BEARPUTSPREAD": 'bear_put_spread',
        "BEARLONGCALENDAR": 'bear_long_calendar'
    }
    return actionMap[strategy] || ''
}

export const AltStatus = () => {
    return ['status']
};

export const colorCode = {
    White: '#fbffff',
    Black: '#220901',
    GOGreen: '#00bf63',
    MidnightBlue: '#003f91',
    AlizarinRed: '#df2935',
    SkyBlue: '#89c2e4',
    Lightblue: '#4dbbff',
    FlavescentYellow: '#f6e292',
    MiddleGreenYellow: '#8B74C5',
    DeepMauvePink: '#87E66C',
    RoyalOrange: '#F99245',
    PaleTaupeGray: '#7AA3B8',
    Silver: '#bbbbbb88'
}
type StatusMap = {
    [key: string]: string;
};

export const strategyStatus = (isExpired: boolean, isVirtual: boolean, isInMoney: boolean = false, zeroPosition: boolean = false): string => {
    const { FlavescentYellow, GOGreen, PaleTaupeGray, Silver, White } = colorCode;
    return isExpired ? Silver : zeroPosition ? `${PaleTaupeGray}88` : isVirtual ? `${FlavescentYellow}33` : isInMoney ? `${GOGreen}33` : White;
}

export const statusColor = (value: string | null): string => {
    const { White, SkyBlue, GOGreen, MiddleGreenYellow } = colorCode;
    const status: StatusMap = {
        "open": White,
        "parFiled": SkyBlue,
        "filed": GOGreen,
        "closed": MiddleGreenYellow,
        "VERTUAL": GOGreen + "66"
    }
    return value && status[value] ? status[value] : ''
}
export const getColor = (side: string) => {
    const actionMap: { [key: string]: string } = {
        "BUY": '#0b56bf',
        "SELL": '#db1222'
    }
    return actionMap[side] || ''
}
export const getColorCode = (status: boolean) => {
    return status ? '#0b56bf' : '#db1222'
}
export const getColorCodePayoff = (status: boolean) => {
    return status ? '#00bf63' : '#e36c64';
}
export const getColorCodeOptionType = (optType: string) => {
    return optType === "C" ? '#e36c64' : '#00bf63'
}
export const userStatus = {
    ACTIVE: "active",
    INACTIVE: "inactive",
    SUSPENDED: "suspended"
}

export const alertColor = {
    SUCCESS: "success",
    ERROR: "danger",
    WARNING: "warning",
    NOTIFICTION: "primary"
}

export const menus = [
    { id: 1, name: 'Dashboard', icon: 'bi-speedometer2', path: '/dashboard', submenus: [] },
    // { id: 2, name: 'Brokers', icon: 'bi-bricks', path: '/brokers', submenus: [] },
    { id: 3, name: 'Orders', icon: 'bi-cart-check', path: '/orders', submenus: [] },
    { id: 4, name: 'User', icon: 'bi-people', path: '/users', submenus: [] },
    { id: 5, name: 'Settings', icon: 'bi-gear', path: '#', submenus: [
        { id: 6, name: 'Sub Setting 1', icon: 'bi-sliders', path: '/user/add' },
        { id: 7, name: 'Sub Setting 2', icon: 'bi-sliders', path: '/user/edit' },
    ] },
];

export const Breadcrumb = (page: string): { title: string; link: string; }[] | '' => {
    const list: any = {
        "IBKRSETUP": [{'title': 'Dashboard', 'link': 'dashboard'}, {'title': 'Brokers', 'link': 'brokers'}, {'title': 'IBKR SetUP', 'link': ''}],
    }
    return page && list[page] ? list[page] : ''
}

export const OptionChainHeader = () => {
    return [
        { id: 'call_delta', label: 'Delta C' },
        { id: 'call_gamma', label: 'Gamma C' },
        { id: 'call_theta', label: 'Theta C' },
        { id: 'call_vega', label: 'Vega C' },
        { id: 'call_implied_vol_%', label: 'IV C' },
        { id: 'call_last_price', label: 'LTP', price: true, cellClick: true, visible: true  },
        { id: 'call_bid_size', label: 'QtyB', visible: true  },
        { id: 'call_bid_price', label: 'Bid', cellClick: true, visible: true },
        { id: 'call_ask_price', label: 'Ask', cellClick: true, visible: true },
        { id: 'call_ask_size', label: 'QtyA', visible: true  },
        { id: 'strike_price', label: 'Strike', price: true, visible: true  },
        { id: 'put_bid_size', label: 'QtyB', visible: true  },
        { id: 'put_bid_price', label: 'Bid', cellClick: true, visible: true },
        { id: 'put_ask_price', label: 'Ask', cellClick: true, visible: true },
        { id: 'put_ask_size', label: 'QtyA', visible: true  },
        { id: 'put_last_price', label: 'LTP', price: true, cellClick: true, visible: true  },
        { id: 'put_implied_vol_%', label: 'IV P' },
        { id: 'put_vega', label: 'Vega P' },
        { id: 'put_theta', label: 'Theta P' },
        { id: 'put_gamma', label: 'Gamma P' },
        { id: 'put_delta', label: 'Delta P' },
    ]
}

export const OExchange = [
    {label: 'CME', value: 'CME'}
]

export const OBroker = [
    {label: 'IBKR', value: 'IBKR'}
]

export const OTradeType = [
    {label: 'DAY', value: 'DAY'},
    {label: 'IOC', value: 'IOC'},
    {label: 'GTC', value: 'GTC'},
    {label: 'GTD', value: 'GTD'},
    {label: 'OPG', value: 'OPG'},
]

export const OOrderType = [
    {label: 'Limit', value: 'LMT'},
    {label: 'Market', value: 'MKT'},
    {label: 'Stop', value: 'STP'},
    {label: 'Stop Limit', value: 'STPLMT'},
    {label: 'MIT', value: 'MIT'},
    {label: 'LIT', value: 'LIT'},
    {label: 'Trailing Stop', value: 'TRAILSTP'},
    {label: 'Trailing Stop Limit', value: 'TRAILLMT'},
]

export const ODepth = [
    {label: '10', value: '10'},
    {label: '20', value: '20'},
    {label: '30', value: '30'},
    {label: '40', value: '40'},
    {label: '50', value: '50'}
]

export const OSymbol = [
    { label: 'ES', value: 'ES' }
]
export const EmptyRow = [
    {label: '', value: ''}
]

export const ExitPrie = [
    {label: 'Passive', value: 'PASSIVE'},
    {label: 'Aggressive', value: 'AGGRESSIVE'},
    {label: 'Mid', value: 'MID'},
    {label: 'Ltp', value: 'LTP'}
]

export const ExitPosition = [
    {label: '25 %', value: '25'},
    {label: '50 %', value: '50'},
    {label: '75 %', value: '75'},
    {label: '100 %', value: '100'}
]

export const orderType = [
    {label: 'CE', value: 'CALL'},
    {label: 'PE', value: 'PUT'},
]

export const openInterestDepth = [
    {label: '5', value: '5'},
    {label: '10', value: '10'},
    {label: '15', value: '15'},
    {label: '20', value: '20'},
]