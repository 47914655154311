import { getArray, getArrayLength, isFalsy, isNull, isObjectTruthy, isTruthy } from "../../../helpers/Helper";
import { getProductById } from "../../../components/Common/OptionChain/Helper";
import { resetStopLoss } from "../../../provider/StopLoss";
import stopLoss from "../../../subscriber/StopLoss";
import { speakNotification } from "./Helpers";

const stop_loss_template = (trade: any) => {
    const {user_id, product_id, strategy_name } = trade
    return {
        user_id,
        product_id,
        strategy_name,
        is_order_placed: false,
        sl_setting: 0,
        sl_enabled: false,
        trailing_sl: 0
    }
}

export const create_stop_loss_setting = (trades: any[]) => {
    const { settings, updateValue } = stopLoss;
    const new_setting: any[] = [];

    const existingSettings = getArray(settings);

    getArray(trades).forEach((trade) => {
        const tradeExists = existingSettings.some(item => Number(item.product_id) === Number(trade.product_id));
        if (isFalsy(tradeExists)) {
            new_setting.push(stop_loss_template(trade));
        }
    });

    if (getArrayLength(new_setting) > 0) {
        updateValue("settings", [...existingSettings, ...new_setting]);
    }
}

export const check_position_become_zero = (position: any[]) => {
    getArray(position).forEach(item => {
        const { avg_quantity, product_id } = item || {}
        if(isNull(avg_quantity)) {
            resetStopLoss(product_id)
        }
    });
}

/**
 * Checks the stop loss responses against the requests.
 * 
 * This function iterates over the requests for stop loss settings,
 * compares them with the corresponding responses, and triggers notifications
 * if the stop loss prices match. It collects and returns an array of updated
 * responses containing product IDs and their respective stop loss prices.
 * 
 * @param requests - An object containing the requested stop loss prices.
 * @param responses - An object containing the actual stop loss responses.
 * @returns An array of updated responses with product IDs and their stop loss prices.
 */
export const check_stop_loss_response = (requests: any, responses: any) => {
    const updated_response: any[] = [];
    const positions = stopLoss.positions;
    getArray(Object.keys(requests)).map(product_id => {
        const { trailing_price: request_trailing_price } = requests[product_id];
        const response = responses[String(product_id)] || {};
        if (isObjectTruthy(response)) {
            const { sl_price, trailing_price, is_order_placed = false } = response;
            const product = getProductById(product_id)
            const { strike_price, option_type } = product || {}

            // Notify if the trailing_price matches the requested trailing_price
            if (trailing_price && parseFloat(trailing_price) !== parseFloat(request_trailing_price)) {
                speakNotification(`Trailing price of ${strike_price} ${option_type === 'P' ? 'PE' : 'CE'} changing`);
            }
            if(isTruthy(is_order_placed)) {
                const updated_setting = getArray(stopLoss.settings).reduce((acc, setting) => {
                    if(Number(setting.product_id) === Number(product_id)) {
                        acc.push({ ...setting, is_order_placed }); // Update the setting
                    } else {
                        acc.push(setting); // Keep the existing setting
                    }
                    return acc;
                }, []);
                stopLoss.updateValue("settings", updated_setting);
            }
            // Always push the product_id along with its sl_price and trailing_price to the updated_response array
            updated_response.push({ product_id, sl_price, trailing_price });
        }
    });

    if(getArrayLength(positions) === 0) return updated_response;
    
    // {{ edit_1 }}: Merge existing positions with updated_response
    const mergedResponse = getArray(positions).map(item => {
        const position = updated_response.find(pos => pos.product_id === item.product_id);
        if (isObjectTruthy(position)) {
            // Update existing position with new data
            return { ...item, sl_price: position.sl_price, trailing_price: position.trailing_price };
        }
        return item; // Return existing item if no update is found
    });

    // Add new products from updated_response that are not in positions
    updated_response.forEach(newProduct => {
        const exists = mergedResponse.find(pos => pos.product_id === newProduct.product_id);
        if (!exists) {
            mergedResponse.push(newProduct); // Add new product if it doesn't exist
        }
    });

    return mergedResponse;
}