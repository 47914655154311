import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { styled } from '@mui/material';

interface CustomCalendarProps {
    value?: Date | null;
    onChange?: (date: Date | null) => void;
    minDate?: Date;
    maxDate?: Date;
    showTimeSelect?: boolean;
    dateFormat?: string;
    placeholderText?: string;
    style?: React.CSSProperties;
    extraProps?: any;
    isWeekday?: boolean; // New prop for displaying only weekdays
    holidaylists?: any[];
    key?: string;
}

const DatePickerContainer = styled('div')(({ theme }) => ({
    '.react-datepicker-wrapper': {
        display: 'inline-block',
        width: '100%',
    },
    '.react-datepicker__input-container': {
        display: 'inline-block',
        width: '100%',
    },
    '.react-datepicker__input-container input': {
        width: '100%',
        height: '100%',
        padding: '0.8rem',
        fontSize: '12px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        color: '#333',
        outline: 'none',
        transition: 'border-color 0.3s ease-in-out',
    },
    '.react-datepicker__input-container input:focus': {
        borderColor: '#2196F3',
    },
    '.react-datepicker__day--disabled': {
        color: '#ccc',
    },
    '.react-datepicker-popper': {
        zIndex: 1050,
    },
}));

export const CustomCalendar: React.FC<CustomCalendarProps> = ({
    value,
    onChange,
    minDate,
    maxDate,
    showTimeSelect = false,
    dateFormat = "MMMM d, yyyy",
    placeholderText = "Select a date",
    style,
    extraProps = {},
    isWeekday = false,
    holidaylists = [], // Default empty array for holidays
    key = ""
}) => {
    const [startDate, setStartDate] = useState<Date | null>(value || null);

    const handleChange = (date: Date | null) => {
        setStartDate(date);
        if (onChange) {
            onChange(date);
        }
    };

    const filterWeekdays = (date: Date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const filterHolidays = (date: Date, holidays: Date[]) => {
        return !holidays.some((holiday) => {
            // Ensure holiday is a valid Date object
            const holidayDate = holiday instanceof Date ? holiday : new Date(holiday);
    
            if (isNaN(holidayDate.getTime())) {
                // Skip invalid dates
                return false;
            }
    
            return (
                date.getFullYear() === holidayDate.getFullYear() &&
                date.getMonth() === holidayDate.getMonth() &&
                date.getDate() === holidayDate.getDate()
            );
        });
    };

    const filterDates = (date: Date) => {
        const isWeekdayValid = isWeekday ? filterWeekdays(date) : true;
        const isHolidayValid = holidaylists ? filterHolidays(date, holidaylists) : true;
        return isWeekdayValid && isHolidayValid;
    };

    const { inputClassName = '', wrapperClassName = '', inputStyle = {}, ...restProps } = extraProps;

    return (
        <DatePickerContainer style={style}>
            <DatePicker
                key={key}
                selected={startDate}
                onChange={handleChange}
                minDate={minDate}
                maxDate={maxDate}
                showTimeSelect={showTimeSelect}
                dateFormat={dateFormat}
                placeholderText={placeholderText}
                filterDate={filterDates}
                className={inputClassName}
                wrapperClassName={wrapperClassName}
                style={inputStyle}
                {...restProps}
            />
        </DatePickerContainer>
    );
};
