import moment from "moment";
import { getArray, getArrayLength, getObject, isFalsy, isNull, isTruthy } from "../../../helpers/Helper";
import { getProductById } from "../../../components/Common/OptionChain/Helper";
import { calculate_realised_pnl } from "../../Orders/helpers/PositionHelper";
import { create_product_dec, getLTP } from "../../Orders/helpers/Helper";
import openInterest from "../../../subscriber/OpenInterest";
import option_chain from "../../../subscriber/OptionChain";
import orders from "../../../subscriber/Orders";
import Virtual_order from "../../../subscriber/VirtualOrder";
import deltaStrategies from "../../../subscriber/DeltaStrategies";
import Analyze, { StrategyLeg } from "../../../subscriber/Analyze";

interface TradeRecord {
    product_id: number;
    action: "Sell" | "Buy";
    type: string;
    strike: number;
    expiry: string;
    premium: number;
    size: number;
    multiplier: number;
    realized_pnl: number;
    selected: boolean;
}

// Helper function to update original data with updated data
export const updateDataArray = (
    originalData: StrategyLeg[],
    updatedData: StrategyLeg[]
): StrategyLeg[] => {
    // Create a map of updated data for quick access by product_id
    const updatedDataMap = new Map(
        updatedData.map((item) => [item.product_id, item])
    );
    
    // Loop through the original data and update values if they exist in updatedData
    const result = originalData.map((item) => {
        // Check if there's an updated version of the current item
        const updatedItem = updatedDataMap.get(item.product_id);
    
        // If an updated item exists, merge it with the original item; otherwise, keep the original item
        return updatedItem ? { ...item, ...updatedItem } : item;
    });
    
      // Add any new items from updatedData that are not in originalData
    updatedData.forEach((item) => {
        if (!originalData.some((orig) => orig.product_id === item.product_id)) {
          result.push(item); // Add new item to the result
        }
    });
  
    // Return the fully updated array
    return result;
};

export const formatAnalyzeData = (strategy_trades: any): StrategyLeg[] => {
    const { selected_strategy } = Analyze
    const current_oreder: any[] =  Object.keys(getObject(strategy_trades)).map(key => {
        const aggregatedTrade = getArray(strategy_trades[key]).reduce((acc, trade) => {
            const { quantity, price, product_id, side, strategy_name, traded_product, selected = true } = trade;
            const numQuantity = Number(quantity);
            const totalPrice = parseFloat(price) * numQuantity;

            if (selected_strategy?.value !== 'ALL' && String(strategy_name) !== String(selected_strategy?.value)) {
                return acc; // Skip if strategy_name doesn't match the strategy_id
            }

            if (side === "BUY") {
                acc.buy_avg += totalPrice;
                acc.buy_qty += numQuantity;
            } else {
                acc.sell_avg += totalPrice;
                acc.sell_qty += numQuantity;
            }

            acc.avg_quantity += side === "BUY" ? numQuantity : -numQuantity;
            acc.product_id = product_id;
            acc.traded_product = traded_product
            acc.side = side
            acc.isSelected = isTruthy(selected)
            return acc;
        }, { avg_quantity: 0, buy_avg: 0, sell_avg: 0, buy_qty: 0, sell_qty: 0, product_id: '', traded_product: {}, side: '', isSelected: true });
        if (!isNull(aggregatedTrade.product_id)) {
            const { avg_quantity, buy_avg, sell_avg, buy_qty, sell_qty, product_id, traded_product, isSelected } = aggregatedTrade
            if (isFalsy(isSelected)) {
                return null;
            }
            const realised_pnl = calculate_realised_pnl(getArray(strategy_trades[key]));

            const { option_type, strike_price, instrument, lot_size, expiry = '' } = traded_product || {}
            const action: "Buy" | "Sell" = avg_quantity > 0 ? "Buy" : "Sell";
            let type: "CE" | "PE" | "FUT" = option_type === "C" ? "CE" : "PE";

            let premium: number =  avg_quantity > 0 ? Number(buy_avg) / Number(buy_qty) : Number(sell_avg) / Number(sell_qty);
            let size = Math.abs(avg_quantity);
            if (instrument === 'FUT') {
                type = instrument
            }

            const multiplier = action === "Buy" ? 1 * lot_size : -1 * lot_size
            return {
                product_id: product_id,
                action: action,
                type: type,
                strike: Number(strike_price) === 0 ? premium : Number(strike_price),
                expiry: expiry,
                premium: parseFloat(premium.toFixed(3)),
                size: size,
                multiplier: multiplier,
                realized_pnl: Number(realised_pnl),
                selected: isSelected
            };
        }
        return null;
    }).filter(Boolean);
    
    const draft_order: StrategyLeg[] = formatDraftVOData();
    const compile_order: any[] = combineAllOrder(current_oreder, draft_order);
    
    return compile_order;
}

export const formatDraftVOData = (): StrategyLeg[] => {
    const { draftVirtualOrder = [] } = Virtual_order;
    const cloneDraft = JSON.parse(JSON.stringify(draftVirtualOrder));

    return getArray(cloneDraft)
    .filter((item: any) => isTruthy(item.selected))
    .map((item: any): StrategyLeg => {
        const { product_id, quantity, price, side, putOrCall, selected, strike = 0 } = item;
        const { lot_size = 50, expiry = '' } = getProductById(product_id) ?? {};
        const action: "Buy" | "Sell" = side === "BUY" ? "Buy" : "Sell";
        const type: "CE" | "PE" | "FUT" = putOrCall === "CALL" ? "CE" : "PE";
        const multiplier = side === "BUY" ? 1 * lot_size : -1 * lot_size
        return {
            product_id: product_id,
            action: action,
            type: type,
            strike: Number(strike) === 0 ? price : Number(strike),
            expiry: expiry,
            premium: isNaN(Number(price)) ? 0 : parseFloat(Number(price).toFixed(3)),
            size: Number(quantity),
            multiplier: multiplier,
            realized_pnl: 0,
            selected: isTruthy(selected)
        };
    });
}

const combineAllOrder = (data: TradeRecord[], draft_order: any[]): TradeRecord[] => {
    if (!getArrayLength(data)) return [...draft_order];
    if (!getArrayLength(draft_order)) return [...data];

    // Step 1: Process data and merge with draft_order
    const mergedOrders = data.map(item => {
        const match = draft_order.find((order: any) => order.product_id === item.product_id);
        if (!match) return item; // If no matching order, keep the original item

        const { action, premium, size, multiplier } = match;
        const isSameAction = action === item.action;

        // Compute new size and premium
        const newSize = isSameAction ? item.size + size : Math.abs(item.size - size);
        const newPremium = newSize === 0 ? 0 : isSameAction ? 
            ((item.premium * item.size) + (premium * size)) / newSize 
            : item.size > size ? item.premium : premium;
        const trades = allTrades(String(item.product_id))
        const realised_pnl = calculate_realised_pnl(trades)
        return {
            ...item,
            action: newSize >= 0 ? "Buy" : "Sell",
            premium: newPremium,
            size: newSize,
            realized_pnl: Number(realised_pnl),
            multiplier: Math.sign(newSize) * multiplier
        };
    });

    // Step 2: Add draft_order records that were NOT matched
    const unmatchedDraftOrders = draft_order.filter(order => !data.some((item: any) => item.product_id === order.product_id));

    // Step 3: Combine both arrays
    return [...mergedOrders, ...unmatchedDraftOrders] as TradeRecord[];
};

const allTrades = (product_id: string) => {
    const { selected_strategy }= Analyze
    const { draftVirtualOrder } = Virtual_order
    const { strategy_trades } = orders
    const draft_order = getArray(draftVirtualOrder).find(item => String(item.product_id) === String(product_id)) || {}
    const { side, quantity, price } = draft_order
    const trades = getArray(getObject(strategy_trades)[product_id]).filter(item => {
        return selected_strategy?.value === 'ALL' || String(item.strategy_name) === String(selected_strategy.value);
    });
    const product = getProductById(draft_order.product_id) || {};
    const draft_trade = { "side": side, "quantity": quantity, "price": price, traded_product: product }
    return [ ...trades, draft_trade ]
}

export const changeStrategyLegs = (item: any) => {
    const { selectedStrategyLegs } = Analyze;
    
    // Find the index of the leg with the matching product_id
    const index = getArray(selectedStrategyLegs).findIndex((leg) => leg.product_id === item.product_id);
  
    // If no matching leg is found, return early
    if (index === -1) return;
  
    const leg = { ...selectedStrategyLegs[index] }; // Create a copy of the found leg
    const updatedQty = leg.size - item.quantity;
  
    // If the updated quantity is zero, remove the leg from the array
    if (updatedQty === 0) {
        // Create a new array without the zero quantity leg
        const updatedLegs = selectedStrategyLegs.filter((_, i) => i !== index);
        Analyze.setSelectedStrategyLegs(updatedLegs);
    } else {
        // Otherwise, update the quantity and set the updated legs
        selectedStrategyLegs[index] = { ...leg, size: Math.abs(updatedQty) };
        Analyze.setSelectedStrategyLegs([...selectedStrategyLegs]);
    }
};

export const formedStrategyTrade = (result: any[]): Record<string, any[]> => {
    let updated_response: Record<string, any[]> = {};

    // Group trades by product_id
    getArray(result).forEach(item => {
        const { product_id } = item;
        if (!updated_response[product_id]) {
            updated_response[product_id] = [];
        }

        updated_response[product_id].push(item);
    });

    // Iterate over each product_id to group and sort trades
    let sortedTrades: Record<string, any[]> = {};

    Object.entries(getObject(updated_response)).forEach(([key, value]) => {
        // Group trades by instrument and option_type (C and P)
        const groupedTrades: any[] = Object.values(getArray(value).reduce((acc, trade) => {
            const { traded_product } = trade;
            const groupKey = `${traded_product.instrument}_${traded_product.option_type || ''}`;
            if (!acc[groupKey]) acc[groupKey] = [];
            acc[groupKey].push(trade);
            return acc;
        }, {}));

        // Flatten grouped trades for sorting
        const flattenedGroupedTrades = groupedTrades.flat();

        // Now sort the flattened trades by expiry date, instrument type, and strike price
        const sortedGroupedTrades = flattenedGroupedTrades.sort((tradeA, tradeB) => {
            const expiryA = moment(tradeA?.traded_product?.expiry);
            const expiryB = moment(tradeB?.traded_product?.expiry);

            // Primary sort by expiry date
            if (expiryA.isBefore(expiryB)) return -1;
            if (expiryA.isAfter(expiryB)) return 1;

            const instrumentA = tradeA?.traded_product?.instrument || '';
            const instrumentB = tradeB?.traded_product?.instrument || '';

            // Secondary sort by instrument type (FUT before FOP)
            if (instrumentA === 'FUT' && instrumentB !== 'FUT') return -1;
            if (instrumentA !== 'FUT' && instrumentB === 'FUT') return 1;

            // Tertiary sort by strike price (only for FOP)
            const strikePriceA = tradeA?.traded_product?.strike_price || 0;
            const strikePriceB = tradeB?.traded_product?.strike_price || 0;

            if (strikePriceA < strikePriceB) return -1;
            if (strikePriceA > strikePriceB) return 1;

            return 0;
        });

        // Assign the sorted and grouped trades to the product_id
        sortedTrades[key] = sortedGroupedTrades;
    });

    return sortedTrades;
}

export const createStrategyPositions = (result: any, strategy_id: string): any[] => {
    const strategyPositions = Object.keys(getObject(result)).map((key) => {
        const trades = result[key];
        let isVirtual = false;
        const aggregatedTrade = getArray(trades).reduce((acc, trade) => {
            const { quantity, price, product_id, side, status = "SYSTEM", strategy_name, traded_product } = trade;
            const { expiry, option_type } = traded_product;
            
            // Only perform the calculation if strategy_name matches the strategy_id
            if (strategy_id !== 'ALL' && String(strategy_name) !== String(strategy_id)) {
                return acc; // Skip if strategy_name doesn't match the strategy_id
            }
            const numQuantity = Number(quantity);
            const totalPrice = parseFloat(price) * numQuantity;

            if (side === "BUY") {
                acc.buy_avg += totalPrice;
                acc.buy_qty += numQuantity;
            } else {
                acc.sell_avg += totalPrice;
                acc.sell_qty += numQuantity;
            }
            if (isFalsy(isVirtual) && status === 'VERTUAL') {
                isVirtual = true;
            }
            acc.avg_quantity += side === "BUY" ? numQuantity : -numQuantity;
            acc.product_id = product_id;
            acc.product_dec = create_product_dec(traded_product);
            acc.isExpired = moment(expiry).isBefore(moment(), 'day');
            acc.traded_product = traded_product;
            acc.option_type = option_type;
            return acc;
        }, { avg_quantity: 0, buy_avg: 0, sell_avg: 0, buy_qty: 0, sell_qty: 0, product_id: '', product_dec: '', isExpired: false, traded_product: {} });

        if (!isNull(aggregatedTrade.product_id)) {
            const { avg_quantity, buy_avg, sell_avg, buy_qty, sell_qty, product_id } = aggregatedTrade;
            const side = avg_quantity > 0 ? "BUY" : "SELL";
            aggregatedTrade['isVirtual'] = isVirtual;
            aggregatedTrade["product_id"] = product_id.toString();
            aggregatedTrade["side"] = side;
            aggregatedTrade["type"] = avg_quantity > 0 ? 'B' : 'S';
            aggregatedTrade["row_avg"] = avg_quantity > 0 ? buy_avg / buy_qty : sell_avg / sell_qty;
            return aggregatedTrade;
        }
        return null;
    }).filter(Boolean);

    // Sort the strategy positions to move zero positions to the end
    getArray(strategyPositions).sort((a, b) => {
        return a.avg_quantity === 0 ? 1 : b.avg_quantity === 0 ? -1 : 0;
    });

    return strategyPositions
}

export const selecte_all_position = (strategyPositions: any = {}) => {
    // Convert the object values to an array
    return Object.values(strategyPositions).flat(); // Flatten in case of nested arrays
};

export const get_underlying_price = () => {
    const { conid } = getObject(option_chain.future[0])
    const { ltp = 0 } = getLTP(conid)
    return Number(ltp)
}

export const parseOpenInterest = (value: string | undefined) => {
    try {
        // Ensure value is defined and is a string
        if (typeof value === 'string') {
            if (value.includes('K')) {
                return parseFloat(value.replace('K', '')) * 1000;
            } else if (value.includes('L')) {
                return parseFloat(value.replace('L', '')) * 100000;
            }
            return parseFloat(value);
        } else {
            return 0;
        }
    } catch (error) {
        return 0;
    }
};

export const get_execting_open_interest_ids = () => {
    const { products } = openInterest;
    const ids = getArray(products).map((item) => item.id);
    return ids.join(",");
}


export const get_execting_delta_strategies_ids = () => {
    const { products } = deltaStrategies;
    const ids = getArray(products).map((item) => item.id);
    return ids.join(",");
}

export const speakNotification = (message: string) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(message);
      const voices = window.speechSynthesis.getVoices();
      utterance.voice = voices[0]; // Choose the first available voice; adjust as needed
      window.speechSynthesis.speak(utterance);
    } else {
      alert('Your browser does not support speech synthesis. Please use a compatible browser.');
    }
};

export const findIntheMoney = (strike_price: string, option_type: string) => {
    const ltp = get_underlying_price();
    if(parseFloat(String(ltp)) >= parseFloat(strike_price) &&  option_type === 'C') {
        return true
    }else if(parseFloat(String(ltp)) <= parseFloat(strike_price) &&  option_type === 'P') {
        return true
    }
    return false
}

export const removePrefixMarket = (marketItem: Record<string, any>, option_type: string) => {
    const prefix = option_type === "C" ? "call_" : "put_";
  
    // Create prefixed object keys
    const prefixedMarket = Object.entries(marketItem).reduce((acc, [key, value]) => {
        // Check if the key starts with the prefix and remove it
        if (key.startsWith(prefix)) {
            const newKey = key.slice(prefix.length); // Remove the prefix
            acc[newKey] = value; // Assign value to the new key
        } else {
            acc[key] = value; // Keep the original key
        }
        return acc;
    }, {} as Record<string, any>);
    return prefixedMarket;
}