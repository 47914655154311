import config from "../subscriber/Config";
import users from "../subscriber/Users";
import { apiUrls } from "./Config";
import { getObject, isFalsy } from "./Helper";

interface CallApiOptions {
    baseUrl?: string;
    functionUrl?: string;
    requestBody?: Record<string, any>;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';  // Adjust based on allowed methods
    urlValue?: string | number | null;
    params?: string | null;
    isBackground?: boolean;
    headerVal?: Record<string, string> | false;
    metaBody?: Record<string, any> | false;
}

interface Headers {
    'Accept': string;
    'Content-Type': string;
    [key: string]: string;  // This line allows any number of additional string properties
}

interface RequestBody {
    method: string;
    headers: Headers;  // Assuming Headers type is already defined as shown earlier
    body?: any;  // The '?' makes the body optional
}

interface ApiResponse {
    status: boolean;
    status_code: number;
    result?: Record<string, any> | any;
}

export const callApi = ({
    baseUrl = '',
    functionUrl = '',
    requestBody = {},
    method = 'POST',
    urlValue = null,
    params = '',
    isBackground = false,
    headerVal = false,
    metaBody = false,
}: CallApiOptions): Promise<ApiResponse> => {
    
    isFalsy(isBackground) && config.updateValue('loading', true);
    if (metaBody) {
        requestBody['meta'] = metaBody;
    }
    console.log("requestBody => ", requestBody);
    // setting Body
    const requestInfo = JSON.stringify(requestBody);

    // Setting the Herader
    let headers: Headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };

    // Adding more herader
    if (config.authorization && config.session_id) {
        const { account_id = '', role_id } = users;
        headers['Authorization'] = config.authorization;
        headers['Session-id'] = config.session_id;
        headers['account_id'] = account_id;
        headers['account-id'] = account_id;
        headers['account_id1'] = account_id;
        headers['role'] = String(role_id);
    } else if (config.request_id) {
        headers['Request-id'] = config.request_id;
    }

    if (headerVal !== false) {
        for (let i in getObject(headerVal)) {
            headers[i] = headerVal[i];
        }
    }

    let requestObj: RequestBody = {
        method,
        headers: headers,
        body: requestInfo
    };

    if (method === 'GET') {
        delete requestObj.body;
    }

    // We are handling Network errors
    const handleErrors = (response: Response) => {
        if (!response.ok) {
            config.updateMultiValue({
                "apiError": response.statusText,
                "loading": false
            });
        }
    };

    console.log(requestObj);

    let url = baseUrl + functionUrl;
    if (apiUrls[functionUrl]) {
        url = baseUrl + apiUrls[functionUrl];
    }
    if (urlValue) {
        url = functionUrl ? url + '/' + urlValue : url + urlValue;
    }
    if(params) {
        url = url + '?' + params;
    }
    console.log(url);
    // All core API will be called from here
    return fetch(url, requestObj)
        .then(response => {
            handleErrors(response);
            const result = response.json();
            result.then(data => {
                console.log("response => ", data);
                config.updateValue("loading", false);
                // We are checking common errors
                const { status = false, result, status_code = '' } = getObject(data);
                if (!status && status_code === 101 && config.apiErrorCode !== 101) {
                    config.updateMultiValue({
                        'apiError': result,
                        'apiErrorCode': status_code
                    })
                }
            });
            return result;
        })
        .catch(async error => {
            console.log('Erorr ', error);
            let message = "We're sorry, but something went wrong. Please try again later.";
            if (error.message === 'Network request failed') {
                message = "Oops! Network issue detected. Please retry or contact support for help.";
            }
            config.updateMultiValue({
                'apiError': message,
                "loading": false
            });
        });
};