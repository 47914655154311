import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { isObjectTruthy } from '../helpers/Helper';

export interface StrategyLeg {
    product_id: string,
    action: "Buy" | "Sell";
    type: "CE" | "PE" | "FUT";
    strike: number;
    expiry: string;
    premium: number;
    size: number;
    multiplier: number;
    realized_pnl: number;
    selected: boolean;
    volatility?: number;
    riskFreeRate?: number;
}

interface StandardDeviationPoint {
    label: string;
    value: string;
}

interface IValues {
    [key: string]: any;
}

class AnalyzeStore {
    selectedStrategyLegs: StrategyLeg[] = [];
    rfr: number = 0.0675;
    maxProfit: string = "0.000";
    maxLoss: string = "0.000";
    probabilityOfProfit: string = "0.000";
    breakEvenPoints: string[] = [];
    sdPoints: StandardDeviationPoint[] = [];
    currentPNL: string = ""
    isModify: boolean = false
    selected_product: string = ""
    projectedGain: number = 0;
    priceOption: any = {};
    is_black_scholes: boolean = false;
    y_axis: number = 25000;
    target_date: Date = new Date();
    // this only for Analyze page
    selected_strategy: any = {}

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'AnalyzeStore',
            properties: ['rfr', 'maxProfit', 'maxLoss', 'probabilityOfProfit', 'breakEvenPoints', 'sdPoints'],
            storage: localStorage
        });
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        (this as any)[key] = value;
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        if(isObjectTruthy(values)) {
            Object.assign(this, values);
        }
    }

    setSelectedStrategyLegs = (legs: StrategyLeg[]) => {
        this.selectedStrategyLegs = legs;
    }

    reset = () => {
        this.selectedStrategyLegs = []
        this.rfr = 0.0675;
        this.maxProfit = "0.000";
        this.maxLoss = "0.000";
        this.probabilityOfProfit = "0.000";
        this.breakEvenPoints = [];
        this.sdPoints = [];
        this.currentPNL = "";
        this.isModify = false;
        this.selected_product = "";
        this.priceOption = {};
        this.is_black_scholes = false;
        this.y_axis = 25000;
        this.target_date = new Date();
        this.selected_strategy = {};
    }
}

const Analyze = new AnalyzeStore();
export default Analyze;
