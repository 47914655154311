import React, { ChangeEvent } from 'react';

interface CustomCheckBoxProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    className?: string;
    inputClass?: string;
    style?: React.CSSProperties;
    labelClass?: string | null;
    error?: string;
    extraProps: any
}

export const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({
    label,
    checked,
    onChange,
    className,
    inputClass,
    style = {},
    labelClass,
    error,
    extraProps
}) => {
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <div className={`form-check ${className}`} style={style}>
            <input
                type="checkbox"
                id={label}
                checked={checked}
                onChange={handleCheckboxChange}
                className={`form-check-input ${inputClass}`}
                {...extraProps}
            />
            <label htmlFor={extraProps['id']} className={`form-check-label ${labelClass}`}>
                {label}
            </label>
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
