import { makeAutoObservable } from 'mobx';
import { getObject } from '../helpers/Helper';
import { makePersistable } from 'mobx-persist-store';

interface IValues {
    [key: string]: any;
}

class OptionChain {
    exchange: any = {}
    symbol: any = {}
    expiryDate: Date | null = new Date();
    depth: any = {}
    products: any[] = [];
    future: any[] = [];
    marketData: any = [];
    optionChainData: any[] = [];

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'OptionChainStore',
            properties: ['products', 'future'],
            storage: localStorage
        });
    }

    // Update or add a single key-value pair
    updateValue = (key: string, value: any) => {
        if (key === 'expiryDate') {
            const date = new Date(value);
            if (!isNaN(date.getTime())) {
                this.expiryDate = date; // Only set if the date is valid
            } else {
                console.error("Invalid date value:", value);
                // Handle the invalid date case, e.g., by not setting the expiryDate
            }
        } else {
            (this as any)[key] = value;
        }
    }

    // Update or add multiple key-value pairs
    updateMultiValue = (values: IValues) => {
        Object.keys(getObject(values)).forEach((key) => {
            if (key === 'expiryDate') {
                const date = new Date(values[key]);
                if (!isNaN(date.getTime())) {
                    this.expiryDate = date;
                } else {
                    console.error("Invalid date value:", values[key]);
                    // Handle the invalid date case, e.g., by not setting the expiryDate
                }
            }
            (this as any)[key] = values[key];
        });
    }

    reset = () => {
        this.exchange = {};
        this.symbol = {};
        this.expiryDate = new Date();
        this.depth = {};
        this.products = [];
        this.future = [];
        this.marketData = [];
        this.optionChainData = []
    }
}
const option_chain = new OptionChain();
export default option_chain;
